import React, {useState, useEffect} from "react";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {FileUpload} from "primereact/fileupload";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {InputNumber} from "primereact/inputnumber";
import axios from "axios";
import {base, contract_acceptances} from "../../../api";

export default function EditACTApplicationDialog({rowData, toast, closeDialog, refreshData}) {
	const [contractName, setContractName] = useState("");
	const [actNumber, setActNumber] = useState("");
	const [editedDate, setEditedDate] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);

	const [acceptanceProducts, setAcceptanceProducts] = useState([]);
	const [removedProducts, setRemovedProducts] = useState([]);

	useEffect(() => {
		if (rowData) {
			setContractName(rowData.contract || "");
			setActNumber(rowData.formatted_contract_acceptance_number || "");
			if (rowData.acceptance_date) {
				setEditedDate(new Date(rowData.acceptance_date));
			}

			// Initialize acceptanceProducts from rowData.acceptance_products
			const products = (rowData.acceptance_products || []).map(p => ({
				...p,
				price: p.price || 0,
				count: p.count || 1,
			}));
			setAcceptanceProducts(products);
			setRemovedProducts([]);
		}
	}, [rowData]);

	const handleFileUpload = event => {
		if (event.files && event.files.length > 0) {
			setUploadedFile(event.files[0]);
			toast?.current?.show({
				severity: "info",
				summary: "File Selected",
				detail: event.files[0].name,
				life: 3000,
			});
		}
	};

	const removeProduct = row => {
		setAcceptanceProducts(prev => prev.filter(item => item.id !== row.id));
		setRemovedProducts(prev => [...prev, row]);
	};

	const reAddProduct = row => {
		setRemovedProducts(prev => prev.filter(item => item.id !== row.id));
		setAcceptanceProducts(prev => [...prev, row]);
	};

	const handlePriceChange = (productId, newPrice) => {
		setAcceptanceProducts(prev =>
			prev.map(p => (p.id === productId ? {...p, price: newPrice} : p))
		);
		setRemovedProducts(prev => prev.map(p => (p.id === productId ? {...p, price: newPrice} : p)));
	};

	const handleCountChange = (productId, newCount) => {
		setAcceptanceProducts(prev =>
			prev.map(p => (p.id === productId ? {...p, count: newCount} : p))
		);
		setRemovedProducts(prev => prev.map(p => (p.id === productId ? {...p, count: newCount} : p)));
	};
	console.log(rowData);

	const saveChanges = async () => {
		if (!rowData?.id) {
			toast?.current?.show({
				severity: "error",
				summary: "Error",
				detail: "No valid row selected",
				life: 3000,
			});
			return;
		}

		const dateToSave = editedDate
			? editedDate.toISOString().split("T")[0]
			: rowData.acceptance_date || "";

		const acceptanceBody = {
			contract: contractName,
			contract_id: rowData.contract_id,
			demand_letter_id: rowData.demand_letter_id,
			formatted_contract_acceptance_number: actNumber,
			acceptance_date: dateToSave,
			acceptance_products: acceptanceProducts.map(p => ({
				id: p.id,
				product: p.product,
				count: p.count,
				price: p.price,
				description: p.description,
				unit: p.unit,
			})),
		};

		try {
			await axios.put(`${base + contract_acceptances}${rowData.id}/`, acceptanceBody, {
				headers: {"Content-Type": "application/json"},
			});

			if (uploadedFile) {
				const formData = new FormData();
				formData.append("acceptance_document", uploadedFile);

				await axios.patch(`${base + contract_acceptances}${rowData.id}/`, formData, {
					headers: {"Content-Type": "multipart/form-data"},
				});
			}

			toast?.current?.show({
				severity: "success",
				summary: "Success",
				detail: "Changes saved successfully!",
				life: 3000,
			});
			closeDialog();
			refreshData();
		} catch (error) {
			console.error("Error saving changes:", error);
			toast?.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to save changes.",
				life: 3000,
			});
		}
	};
	const totalPriceBody = rowData => {
		return <div>{(rowData.count * rowData.price).toLocaleString()}</div>;
	};
	return (
		<div>
			<div className="row mb-3">
				<div className="col-6">
					<label className="fw-bolder">Պայմանագրի համար</label>
					<InputText
						value={contractName}
						onChange={e => setContractName(e.target.value)}
						className="w-100"
					/>
				</div>
				<div className="col-6">
					<label className="fw-bolder">Ակտի համար</label>
					<InputText
						value={actNumber}
						onChange={e => setActNumber(e.target.value)}
						className="w-100"
					/>
				</div>
			</div>

			<div className="row mb-3">
				<div className="col-6">
					<label className="fw-bolder">Ամսաթիվ</label>
					<Calendar
						value={editedDate}
						onChange={e => setEditedDate(e.value)}
						dateFormat="dd.mm.yy"
						className="w-100"
					/>
				</div>
				<div className="col-6">
					<label className="fw-bolder">Հին Ֆայլ</label>
					{rowData?.acceptance_document ? (
						<a
							href={rowData.acceptance_document}
							target="_blank"
							rel="noopener noreferrer"
							className="btn btn-link"
							download
						>
							<Button
								icon="pi pi-download"
								label="Download"
								className="p-button-text p-button-plain"
							/>
						</a>
					) : (
						<span>No document available</span>
					)}
				</div>
			</div>

			<h5>Acceptance Products</h5>
			<DataTable value={acceptanceProducts} responsiveLayout="scroll" className="mb-3">
				<Column field="description" header="Description" className="col-4" />
				<Column
					header="Count"
					className="col-2 text-center"
					body={rowData => (
						<InputNumber
							value={rowData.count || 1}
							onValueChange={e => handleCountChange(rowData.id, e.value)}
							mode="decimal"
							min={0}
						/>
					)}
				/>
				<Column
					header="Price"
					className="col-3 text-center"
					body={rowData => (
						<InputNumber
							value={rowData.price || 0}
							onValueChange={e => handlePriceChange(rowData.id, e.value)}
							mode="decimal"
							min={0}
						/>
					)}
				/>
				<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

				<Column
					header="Remove"
					className="col-3 text-center"
					body={rowData => (
						<Button
							icon="pi pi-trash"
							className="p-button-rounded p-button-danger p-button-text"
							onClick={() => removeProduct(rowData)}
						/>
					)}
				/>
			</DataTable>

			{removedProducts.length > 0 && (
				<>
					<h5>Removed Products</h5>
					<DataTable value={removedProducts} responsiveLayout="scroll" className="mb-3">
						<Column field="description" header="Description" className="col-4" />
						<Column
							header="Count"
							className="col-2 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.count || 1}
									onValueChange={e => {
										const newCount = e.value;
										setRemovedProducts(prev =>
											prev.map(p => (p.id === rowData.id ? {...p, count: newCount} : p))
										);
									}}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column
							header="Price"
							className="col-3 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.price || 0}
									onValueChange={e => {
										const newPrice = e.value;
										setRemovedProducts(prev =>
											prev.map(p => (p.id === rowData.id ? {...p, price: newPrice} : p))
										);
									}}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

						<Column
							header="Re-Add"
							className="col-3 text-center"
							body={rowData => (
								<Button
									icon="pi pi-refresh"
									className="p-button-rounded p-button-success p-button-text"
									onClick={() => reAddProduct(rowData)}
								/>
							)}
						/>
					</DataTable>
				</>
			)}

			<div className="row mb-3">
				<div className="col-12">
					<label className="fw-bolder">Վերբեռնել նոր ֆայլ</label>
					<FileUpload
						name="acceptance_document"
						customUpload
						auto
						uploadHandler={handleFileUpload}
						chooseLabel="Upload File"
						className="w-100"
					/>
				</div>
			</div>

			<div className="mt-4 text-end">
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-secondary mr-2"
					onClick={closeDialog}
				/>
				<Button
					label="Save"
					icon="pi pi-check"
					className="p-button-primary"
					onClick={saveChanges}
				/>
			</div>
		</div>
	);
}
