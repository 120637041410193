// DatatableAdditionalAct.jsx
import React, {useState, useEffect, useRef} from "react";
import {Skeleton} from "primereact/skeleton";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {base, additional_contract_acceptances, partners, projects_url} from "../../../api";
import axios from "axios";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {FaRegFilePdf} from "react-icons/fa";
import AdditionalContractActDialog from "./AdditionalContractActDialog";
import AdditionalContractActEditDialog from "./AdditionalContractActEditDialog";

export default function DatatableAdditionalAct() {
	const [products, setProducts] = useState([]);
	const headers = {"Content-Type": "application/json"};

	const [partnersData, setPartnersData] = useState([]);
	const [projects, setProjects] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [editDialogVisibility, setEditDialogVisibility] = useState(false);
	const [selectedContract, setSelectedContract] = useState(null);
	const toast = useRef(null);

	const formatDate = dateStr => {
		if (!dateStr) return "N/A";
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([
				axios.get(base + additional_contract_acceptances),
				axios.get(base + partners),
				axios.get(base + projects_url),
			])
			.then(
				axios.spread((contractsResponse, partnersResponse, projectsResponse) => {
					const sortedProducts = contractsResponse.data.sort((a, b) => b.id - a.id);
					setProducts(sortedProducts);
					setPartnersData(partnersResponse.data);
					setProjects(projectsResponse.data);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				toast.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to load content.",
					life: 3000,
				});
			});
	};

	const fileBodyTemplate = rowData => {
		if (rowData.acceptance_document) {
			return (
				<a
					target="_blank"
					href={rowData.acceptance_document}
					rel="noopener noreferrer"
					download
					title="Download file"
				>
					<FaRegFilePdf className="fs-3 text-success" />
				</a>
			);
		} else {
			return null;
		}
	};

	const dateBody = rowData => {
		return formatDate(rowData.acceptance_date);
	};

	const countBody = rowData => {
		return <div>{rowData?.acceptance_products.length}</div>;
	};

	const onRowClick = rowData => {
		if (rowData?.acceptance_document) {
			toast.current?.show({
				severity: "info",
				summary: "Info",
				detail: "This contract has a file and cannot be edited.",
				life: 3000,
			});
			return;
		}
		setSelectedContract(rowData);
		setEditDialogVisibility(true);
	};

	const confirmDelete = rowData => {
		confirmDialog({
			message: "Are you sure you want to delete this acceptance?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept: () => handleDelete(rowData),
			reject: () => handleReject(),
		});
	};

	const handleDelete = async rowData => {
		try {
			await axios.delete(`${base + additional_contract_acceptances}${rowData.id}/`, {
				headers: headers,
			});
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Acceptance deleted successfully.",
				life: 3000,
			});
			loadContent();
		} catch (error) {
			console.error("Error deleting acceptance:", error);
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to delete acceptance.",
				life: 3000,
			});
		}
	};

	const handleReject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have canceled the deletion.",
			life: 3000,
		});
	};

	const deleteBodyTemplate = rowData => {
		return (
			<Button
				icon="pi pi-trash"
				className="p-button-rounded p-button-danger p-button-text"
				onClick={e => {
					e.stopPropagation();
					confirmDelete(rowData);
				}}
			/>
		);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<ConfirmDialog />
			<div className="row pb-5">
				<div className="col text-end">
					<Button
						label="Լրացուցիչ պայմանագրի ակտ"
						rounded
						onClick={() => setDialogVisibility(true)}
					/>
				</div>
			</div>
			{products.length > 0 ? (
				<div>
					<DataTable
						value={products}
						dataKey="id"
						tableStyle={{minWidth: "60rem"}}
						rowHover
						onRowClick={e => onRowClick(e.data)}
					>
						<Column field="contract" header="Պայմանագրի համար"></Column>
						<Column field="formatted_contract_acceptance_number" header="Ակտի համար"></Column>
						<Column header="Ամսաթիվ" body={dateBody}></Column>
						<Column header="Count" body={countBody}></Column>
						<Column header="Ֆայլ" body={fileBodyTemplate}></Column>
						<Column body={deleteBodyTemplate} header="Delete"></Column>
					</DataTable>
				</div>
			) : (
				<div>
					<span className="small">Loading Please wait</span>
					<Skeleton className="w-100 mt-2" height="3rem"></Skeleton>
				</div>
			)}
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Լրացուցիչ ակտ</div>}
			>
				<AdditionalContractActDialog
					setDialogVisibility={setDialogVisibility}
					toast={toast}
					loadContent={loadContent}
				/>
			</Dialog>
			<Dialog
				visible={editDialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setEditDialogVisibility(false)}
				header={
					<div className="border-bottom pt-2">
						Խմբագրել Լրացուցիչ ակտ {selectedContract?.formatted_contract_acceptance_number}
					</div>
				}
			>
				<AdditionalContractActEditDialog
					setEditDialogVisibility={setEditDialogVisibility}
					toast={toast}
					loadContent={loadContent}
					data={selectedContract}
				/>
			</Dialog>
		</div>
	);
}
