import React, {useState, useEffect} from "react";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import {
	base,
	partners as partnersUrl,
	expances_row_by_project,
	supply_contracts,
	locations_url,
	projects_with_budget,
} from "../../../api";

export default function EditContractAddForm({setEditDialogVisibility, loadContent, toast, data}) {
	const [contractData, setContractData] = useState({
		deliveryTime: null,
		warrantyPeriod: null,
		paymentPeriod: null,
		startEndDate: {start: null, end: null},
		contractDate: null,
		prepaymentPercentage: null,
		contract_products: [],
		file: null,
	});

	const [oldPrepaymentPercentage, setOldPrepaymentPercentage] = useState(null);
	const [withoutVAT, setWithoutVAT] = useState(false);

	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedBudget, setSelectedBudget] = useState(null);
	const [selectedPartner, setSelectedPartner] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);

	const [removedProducts, setRemovedProducts] = useState([]);

	const [partners, setPartners] = useState([]);
	const [filteredPartners, setFilteredPartners] = useState([]);
	const [partnersLoading, setPartnersLoading] = useState(false);

	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [projectsLoading, setProjectsLoading] = useState(false);

	const [budgetOptions, setBudgetOptions] = useState([]);
	const [filteredBudgetOptions, setFilteredBudgetOptions] = useState([]);
	const [budgetLoading, setBudgetLoading] = useState(false);

	const [locations, setLocations] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [locationsLoading, setLocationsLoading] = useState(false);

	const [loading, setLoading] = useState(false);

	// Load references
	useEffect(() => {
		setPartnersLoading(true);
		axios
			.get(base + partnersUrl)
			.then(r => setPartners(r.data))
			.catch(() => {})
			.finally(() => setPartnersLoading(false));

		setProjectsLoading(true);
		axios
			.get(base + projects_with_budget)
			.then(r => setProjects(r.data))
			.catch(() => {})
			.finally(() => setProjectsLoading(false));

		setLocationsLoading(true);
		axios
			.get(base + locations_url)
			.then(r => setLocations(r.data))
			.catch(() => {})
			.finally(() => setLocationsLoading(false));
	}, []);

	// When project changes, load budgets
	useEffect(() => {
		if (selectedProject?.id) {
			setBudgetLoading(true);
			axios
				.get(base + expances_row_by_project + selectedProject.id + "/")
				.then(r => setBudgetOptions(r.data))
				.catch(() => setBudgetOptions([]))
				.finally(() => setBudgetLoading(false));
		} else {
			setBudgetOptions([]);
			setSelectedBudget(null);
		}
	}, [selectedProject]);

	// Populate contractData from data
	useEffect(() => {
		if (data) {
			setContractData(prev => ({
				...prev,
				deliveryTime: data.delivery_time || null,
				warrantyPeriod: data.warranty_period || null,
				paymentPeriod: data.payment_period || null,
				startEndDate: {
					start: data.start_date ? new Date(data.start_date) : null,
					end: data.end_date ? new Date(data.end_date) : null,
				},
				contractDate: data.contract_date ? new Date(data.contract_date) : null,
				prepaymentPercentage: data.advance_payment || 0,
				contract_products: (data.contract_products || []).map(prod => ({
					...prod,
					count: prod.count || 1,
					price: prod.price || 0,
				})),
				file: null,
			}));
			setWithoutVAT(data.without_vat || false);
		}
	}, [data]);

	// Once references are fetched, map IDs to objects
	useEffect(() => {
		if (!data || projects.length === 0 || partners.length === 0 || locations.length === 0) {
			return;
		}
		let projectObj = null;
		if (data.demand_letter?.project) {
			projectObj = projects.find(p => p.name === data.demand_letter.project);
		} else if (typeof data.project === "number") {
			projectObj = projects.find(p => p.id === data.project);
		}
		const partnerObj = partners.find(p => p.id === data.partner) || null;
		const locationObj = locations.find(l => l.id === data.location) || null;

		// We'll handle budget below in a separate effect
		setSelectedProject(projectObj || null);
		setSelectedPartner(partnerObj);
		setSelectedLocation(locationObj);
	}, [data, projects, partners, locations]);

	// Each time budgetOptions loads or data.budget_category is present, find the object
	useEffect(() => {
		if (!data?.budget_category || budgetOptions.length === 0) return;

		const budgetObj = budgetOptions.find(b => b.id === data.budget_category);
		if (budgetObj) {
			setSelectedBudget(budgetObj);
			console.log("Matched budget category: ", budgetObj);
		} else {
			console.log("No matching budget found for budget_category:", data.budget_category);
		}
	}, [data?.budget_category, budgetOptions]);

	useEffect(() => {
		if (data?.advance_payment) {
			setOldPrepaymentPercentage(data.advance_payment);
		}
	}, [data]);

	// Product remove / re-add
	const removeProduct = p => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.filter(x => x.id !== p.id),
		}));
		setRemovedProducts(prev => [...prev, p]);
	};

	const reAddProduct = p => {
		setRemovedProducts(prev => prev.filter(x => x.id !== p.id));
		setContractData(prev => ({
			...prev,
			contract_products: [...prev.contract_products, p],
		}));
	};

	// Change count / price
	const handleCountChange = (productId, newCount) => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.map(pr =>
				pr.id === productId ? {...pr, count: newCount} : pr
			),
		}));
		setRemovedProducts(prev =>
			prev.map(pr => (pr.id === productId ? {...pr, count: newCount} : pr))
		);
	};

	const handlePriceChange = (productId, newPrice) => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.map(pr =>
				pr.id === productId ? {...pr, price: newPrice} : pr
			),
		}));
		setRemovedProducts(prev =>
			prev.map(pr => (pr.id === productId ? {...pr, price: newPrice} : pr))
		);
	};

	// Save
	const saveData = async () => {
		if (!selectedProject || !selectedBudget) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Project and Budget are required.",
				life: 3000,
			});
			return;
		}
		const validProducts = contractData.contract_products.filter(
			p => p.product && !isNaN(parseFloat(p.count))
		);
		if (validProducts.length === 0) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "No valid products to send.",
				life: 3000,
			});
			return;
		}

		try {
			setLoading(true);
			const requestData = {
				demand_letter_id: data?.demand_letter?.id ?? null,
				project: selectedProject?.id || "",
				partner: selectedPartner?.id || "",
				budget: selectedBudget?.id || "",
				location: selectedLocation?.id || "",
				advance_payment: contractData.prepaymentPercentage || 0,
				contract_date: contractData.contractDate
					? contractData.contractDate.toISOString().split("T")[0]
					: null,
				delivery_time: parseInt(contractData.deliveryTime, 10) || 0,
				start_date: contractData.startEndDate.start
					? contractData.startEndDate.start.toISOString().split("T")[0]
					: null,
				end_date: contractData.startEndDate.end
					? contractData.startEndDate.end.toISOString().split("T")[0]
					: null,
				warranty_period: parseInt(contractData.warrantyPeriod, 10) || 0,
				payment_period: parseInt(contractData.paymentPeriod, 10) || 0,
				without_vat: withoutVAT,
				purchase_price: 0,
				contract_products: validProducts.map(p => ({
					id: p.id || null,
					product: p.product,
					count: parseInt(p.count, 10) || 1,
					description: p.description || null,
					unit: p.unit,
					price: p.price || 0,
				})),
			};

			await axios.patch(`${base + supply_contracts}/${data?.id}/`, requestData, {
				headers: {"Content-Type": "application/json"},
			});
			if (contractData.file) {
				const formData = new FormData();
				formData.append("approved_document", contractData.file);
				await axios.patch(`${base + supply_contracts}/${data?.id}/`, formData, {
					headers: {"Content-Type": "multipart/form-data"},
				});
			}
			loadContent();
			toast.current?.show({
				severity: "success",
				summary: "Success",
				detail: "Contract updated successfully!",
				life: 3000,
			});
			setEditDialogVisibility(false);
		} catch (error) {
			console.error("Failed to update contract:", error?.response?.data || error.message);
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to update contract.",
				life: 3000,
			});
		} finally {
			setLoading(false);
		}
	};

	// Handle form changes
	const handleChange = (e, field) => {
		const value = e.value !== undefined ? e.value : e.target.value;
		setContractData(prev => ({...prev, [field]: value}));
	};

	// Searching
	const searchProject = e => {
		const q = e.query.toLowerCase();
		setFilteredProjects(projects.filter(p => p.full_name_am?.toLowerCase().includes(q)));
	};
	const searchPartner = e => {
		const q = e.query.toLowerCase();
		setFilteredPartners(partners.filter(pt => pt.full_name_am?.toLowerCase().includes(q)));
	};
	const searchBudget = e => {
		const q = e.query.toLowerCase();
		setFilteredBudgetOptions(
			budgetOptions.filter(b => b.category.title_am?.toLowerCase().includes(q))
		);
	};
	const searchLocation = e => {
		const q = e.query.toLowerCase();
		setFilteredLocations(locations.filter(l => l.title_am?.toLowerCase().includes(q)));
	};

	// File upload
	const onFileSelect = e => {
		setContractData(prev => ({...prev, file: e.files[0]}));
	};

	// Toggle VAT
	const toggleWithoutVAT = isChecked => {
		setWithoutVAT(isChecked);
		if (isChecked) {
			if (contractData.prepaymentPercentage !== 0) {
				setOldPrepaymentPercentage(contractData.prepaymentPercentage);
			}
			setContractData(prev => ({...prev, prepaymentPercentage: 0}));
		} else {
			setContractData(prev => ({
				...prev,
				prepaymentPercentage: oldPrepaymentPercentage,
			}));
		}
	};
	const totalPriceBody = rowData => {
		return <div>{(rowData.count * rowData.price).toLocaleString()}</div>;
	};
	return (
		<>
			<div className="row">
				<div className="field col-12 mb-3">
					<label htmlFor="project">Project</label>
					<AutoComplete
						id="project"
						value={selectedProject}
						suggestions={filteredProjects}
						completeMethod={searchProject}
						field="full_name_am"
						onChange={e => setSelectedProject(e.value)}
						placeholder="Search Project by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => <div>{item.full_name_am}</div>}
					/>
				</div>

				{selectedProject && (
					<div className="col-6">
						<label htmlFor="budget">Budget</label>
						<AutoComplete
							id="budget"
							value={selectedBudget}
							suggestions={filteredBudgetOptions}
							completeMethod={searchBudget}
							field="category.title_am"
							onChange={e => {
								setSelectedBudget(e.value);
								console.log("Budget changed to:", e.value);
							}}
							placeholder="Search Budget by Name"
							dropdown
							className="w-100"
							panelStyle={{width: "300px"}}
							loading={budgetLoading}
							itemTemplate={item => <div>{item.category.title_am}</div>}
						/>
					</div>
				)}

				<div className="col-6">
					<label htmlFor="partner">Partner</label>
					<AutoComplete
						id="partner"
						value={selectedPartner}
						suggestions={filteredPartners}
						completeMethod={searchPartner}
						field="full_name_am"
						onChange={e => setSelectedPartner(e.value)}
						placeholder="Search Partner by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						loading={partnersLoading}
						itemTemplate={item => <div>{item.full_name_am}</div>}
					/>
				</div>

				<div className="col-6">
					<label htmlFor="location">Location</label>
					<AutoComplete
						id="location"
						value={selectedLocation}
						suggestions={filteredLocations}
						completeMethod={searchLocation}
						field="title_am"
						onChange={e => setSelectedLocation(e.value)}
						placeholder="Search Location by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						loading={locationsLoading}
						itemTemplate={item => <div>{item.title_am}</div>}
					/>
				</div>

				<div className="col-3">
					<label htmlFor="contractDate">Contract Date</label>
					<Calendar
						id="contractDate"
						value={contractData.contractDate}
						onChange={e => handleChange(e, "contractDate")}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="deliveryTime">Delivery Time</label>
					<InputNumber
						id="deliveryTime"
						value={contractData.deliveryTime}
						onValueChange={e => handleChange(e, "deliveryTime")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="warrantyPeriod">Warranty Period</label>
					<InputNumber
						id="warrantyPeriod"
						value={contractData.warrantyPeriod}
						onValueChange={e => handleChange(e, "warrantyPeriod")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="paymentPeriod">Payment Period</label>
					<InputNumber
						id="paymentPeriod"
						value={contractData.paymentPeriod}
						onValueChange={e => handleChange(e, "paymentPeriod")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>

				<div className="col-3">
					<label htmlFor="startEndDateStart">Start Date</label>
					<Calendar
						id="startEndDateStart"
						value={contractData.startEndDate.start}
						onChange={e =>
							setContractData(prev => ({
								...prev,
								startEndDate: {...prev.startEndDate, start: e.value},
							}))
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="startEndDateEnd">End Date</label>
					<Calendar
						id="startEndDateEnd"
						value={contractData.startEndDate.end}
						onChange={e =>
							setContractData(prev => ({
								...prev,
								startEndDate: {...prev.startEndDate, end: e.value},
							}))
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>
				<div className="col-3">
					<label htmlFor="prepaymentPercentage">Prepayment (%)</label>
					<InputNumber
						id="prepaymentPercentage"
						value={contractData.prepaymentPercentage}
						onValueChange={e => handleChange(e, "prepaymentPercentage")}
						mode="decimal"
						min={0}
						max={100}
						suffix="%"
						className="w-100"
					/>
				</div>
				<div className="col-3 d-flex align-items-center mt-4 pt-2">
					<Checkbox
						inputId="withoutVAT"
						checked={withoutVAT}
						onChange={e => toggleWithoutVAT(e.checked)}
					/>
					<label htmlFor="withoutVAT" className="ml-2">
						Առանց ԱԱՀ
					</label>
				</div>

				<h5 className="mt-3">Contract Products</h5>
				<DataTable
					value={contractData.contract_products}
					responsiveLayout="scroll"
					className="mb-3"
				>
					<Column field="description" header="Description" className="col-4" />
					<Column
						header="Count"
						className="col-2 text-center"
						body={rowData => (
							<InputNumber
								value={rowData.count || 1}
								onValueChange={e => {
									const newCount = e.value;
									handleCountChange(rowData.id, newCount);
								}}
								mode="decimal"
								min={0}
							/>
						)}
					/>
					<Column
						header="Price"
						className="col-3 text-center"
						body={rowData => (
							<InputNumber
								value={rowData.price || 0}
								onValueChange={e => {
									const newPrice = e.value;
									handlePriceChange(rowData.id, newPrice);
								}}
								mode="decimal"
								min={0}
							/>
						)}
					/>
					<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

					<Column
						header="Remove"
						className="col-3 text-center"
						body={rowData => (
							<Button
								icon="pi pi-trash"
								className="p-button-rounded p-button-danger p-button-text"
								onClick={() => removeProduct(rowData)}
							/>
						)}
					/>
				</DataTable>

				{removedProducts.length > 0 && (
					<>
						<h5>Removed Products</h5>
						<DataTable value={removedProducts} responsiveLayout="scroll" className="mb-3">
							<Column field="description" header="Description" className="col-4" />
							<Column
								header="Count"
								className="col-2 text-center"
								body={rowData => (
									<InputNumber
										value={rowData.count || 1}
										onValueChange={e => {
											const newCount = e.value;
											setRemovedProducts(prev =>
												prev.map(p => (p.id === rowData.id ? {...p, count: newCount} : p))
											);
										}}
										mode="decimal"
										min={0}
									/>
								)}
							/>
							<Column
								header="Price"
								className="col-3 text-center"
								body={rowData => (
									<InputNumber
										value={rowData.price || 0}
										onValueChange={e => {
											const newPrice = e.value;
											setRemovedProducts(prev =>
												prev.map(p => (p.id === rowData.id ? {...p, price: newPrice} : p))
											);
										}}
										mode="decimal"
										min={0}
									/>
								)}
							/>
							<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

							<Column
								header="Re-add"
								className="col-3 text-center"
								body={rowData => (
									<Button
										icon="pi pi-refresh"
										className="p-button-rounded p-button-success p-button-text"
										onClick={() => reAddProduct(rowData)}
									/>
								)}
							/>
						</DataTable>
					</>
				)}

				<div className="col-12">
					{data?.approved_document && (
						<div className="my-3">
							<label>Current File</label>
							<div>
								{/\.(jpeg|jpg|gif|png|svg)$/i.test(data.approved_document) ? (
									<img
										src={data.approved_document}
										alt="File Preview"
										style={{maxWidth: "100%", maxHeight: "100px"}}
										className="my-3"
									/>
								) : (
									<div className="mb-2">
										<i className="pi pi-file" style={{fontSize: "2rem"}}></i>
									</div>
								)}
								<a
									href={data.approved_document}
									target="_blank"
									rel="noopener noreferrer"
									download
									className="ms-3 text-primary"
								>
									Download Current File
								</a>
							</div>
						</div>
					)}
				</div>

				<div className="col-12 mt-3 mb-5">
					<label htmlFor="fileUpload">Upload File</label>
					<FileUpload
						name="file"
						auto
						customUpload
						uploadHandler={onFileSelect}
						chooseLabel="Select File"
						className="w-100"
					/>
				</div>

				<div className="col-12 text-center">
					<Button
						label="Cancel"
						rounded
						severity="secondary"
						icon="pi pi-times"
						className="mx-2"
						onClick={() => setEditDialogVisibility(false)}
						disabled={loading}
					/>
					<Button
						rounded
						label={loading ? "Saving..." : "Save"}
						icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
						className="mx-2"
						onClick={saveData}
						disabled={loading}
					/>
				</div>
			</div>
		</>
	);
}
