import React, {useState, useEffect} from "react";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import {
	base,
	projects_with_budget,
	supply_contracts,
	demand_letters, // Keep import but commented UI references
	additional_contract_acceptances,
	additional_contracts,
} from "../../../api";

export default function AdditionalContractActDialog({setDialogVisibility, toast, loadContent}) {
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [selectedProject, setSelectedProject] = useState(null);
	const [projectsLoading, setProjectsLoading] = useState(true);

	const [contracts, setContracts] = useState([]);
	const [filteredContracts, setFilteredContracts] = useState([]);
	const [selectedContract, setSelectedContract] = useState(null);
	const [contractsLoading, setContractsLoading] = useState(false);

	const [contractProducts, setContractProducts] = useState([]);
	const [removedProducts, setRemovedProducts] = useState([]);

	const [uploadDate, setUploadDate] = useState(null);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [fileError, setFileError] = useState("");
	const [loading, setLoading] = useState(false);
	const [responseID, setResponseID] = useState();
	/*
    // ------------------ DEMAND LETTER CODE (commented out for UI) ------------------

    const [demandLetters, setDemandLetters] = useState([]);
    const [filteredDemandLetters, setFilteredDemandLetters] = useState([]);
    const [selectedDemandLetter, setSelectedDemandLetter] = useState(null);
    const [demandLettersLoading, setDemandLettersLoading] = useState(false);

    useEffect(() => {
      if (selectedProject?.id) {
        loadDemandLetters(selectedProject.id);
      } else {
        setDemandLetters([]);
        setFilteredDemandLetters([]);
        setSelectedDemandLetter(null);
      }
    }, [selectedProject]);

    const loadDemandLetters = async projectId => {
      setDemandLettersLoading(true);
      try {
        const res = await axios.get(base + demand_letters);
        const filtered = res.data.filter(dl => dl.project_id === projectId);
        setDemandLetters(filtered);
        setFilteredDemandLetters(filtered);
      } catch (error) {
      } finally {
        setDemandLettersLoading(false);
      }
    };

    const searchDemandLetters = event => {
      if (!event.query.trim().length) {
        setFilteredDemandLetters([...demandLetters]);
      } else {
        const q = event.query.toLowerCase();
        const filtered = demandLetters.filter(
          dl =>
            dl.project_name?.toLowerCase().includes(q) ||
            dl.project_id.toString().includes(q)
        );
        setFilteredDemandLetters(filtered);
      }
    };

    // We'll pick up demand_letter_id from contract's .demand_letter if needed
  */

	useEffect(() => {
		loadProjects();
	}, []);

	const loadProjects = async () => {
		setProjectsLoading(true);
		try {
			const res = await axios.get(base + projects_with_budget);
			setProjects(res.data);
			setFilteredProjects(res.data);
		} catch (error) {
		} finally {
			setProjectsLoading(false);
		}
	};

	useEffect(() => {
		if (selectedProject?.id) {
			loadContracts(selectedProject.id);
		} else {
			setContracts([]);
			setFilteredContracts([]);
			setSelectedContract(null);
			setContractProducts([]);
			setRemovedProducts([]);
		}
	}, [selectedProject]);
	console.log(selectedContract);

	const loadContracts = async projectId => {
		setContractsLoading(true);
		try {
			const res = await axios.get(base + additional_contracts);
			const filtered = res.data.filter(c => c.demand_letter?.project_id === projectId);
			setContracts(filtered);
			setFilteredContracts(filtered);
		} catch (error) {
		} finally {
			setContractsLoading(false);
		}
	};

	useEffect(() => {
		if (selectedContract) {
			const initial = (selectedContract?.contract_products || []).map(p => ({
				...p,
				price: p.price || 0,
				count: p.count || 1,
			}));
			setContractProducts(initial);
			setRemovedProducts([]);
		} else {
			setContractProducts([]);
			setRemovedProducts([]);
		}
	}, [selectedContract]);

	const removeProduct = rowData => {
		setContractProducts(prev => prev.filter(p => p.id !== rowData.id));
		setRemovedProducts(prev => [...prev, rowData]);
	};

	const reAddProduct = rowData => {
		setRemovedProducts(prev => prev.filter(p => p.id !== rowData.id));
		setContractProducts(prev => [...prev, rowData]);
	};

	const handlePriceChange = (productId, newPrice) => {
		setContractProducts(prev => prev.map(p => (p.id === productId ? {...p, price: newPrice} : p)));
		setRemovedProducts(prev => prev.map(p => (p.id === productId ? {...p, price: newPrice} : p)));
	};

	const handleCountChange = (productId, newCount) => {
		setContractProducts(prev => prev.map(p => (p.id === productId ? {...p, count: newCount} : p)));
		setRemovedProducts(prev => prev.map(p => (p.id === productId ? {...p, count: newCount} : p)));
	};

	const searchProjects = event => {
		if (!event.query.trim().length) {
			setFilteredProjects([...projects]);
		} else {
			const q = event.query.toLowerCase();
			const filtered = projects.filter(
				proj => proj.full_name_am?.toLowerCase().includes(q) || proj.id.toString().includes(q)
			);
			setFilteredProjects(filtered);
		}
	};

	const searchContracts = event => {
		if (!event.query.trim().length) {
			setFilteredContracts([...contracts]);
		} else {
			const q = event.query.toLowerCase();
			const filtered = contracts.filter(
				c => c.formatted_contract_number?.toLowerCase().includes(q) || c.id.toString().includes(q)
			);
			setFilteredContracts(filtered);
		}
	};

	const onFileSelect = e => {
		const file = e.files && e.files[0];
		if (file) {
			setFileError("");
			setUploadedFile(file);
		}
	};
	// console.log(selectedContract.contract_number);

	const handleSubmit = async () => {
		const hiddenDemandLetterID = selectedContract?.demand_letter?.id;
		if (
			!uploadDate ||
			!selectedContract ||
			!selectedProject ||
			contractProducts.length === 0 ||
			!hiddenDemandLetterID
		) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Contract ID, Demand Letter ID, Acceptance Products, and Date are required.",
				life: 3000,
			});
			return;
		}

		const acceptanceDate =
			uploadDate instanceof Date ? uploadDate.toISOString().split("T")[0] : uploadDate;

		const acceptanceBody = {
			contract_id: selectedContract?.id,
			demand_letter_id: hiddenDemandLetterID,
			acceptance_date: acceptanceDate,
			contract_acceptance_number: selectedContract?.id,
			acceptance_products: contractProducts.map(p => ({
				product: p.product,
				count: p.count,
				description: p.description || null,
				unit: p.unit,
				price: p.price,
			})),
		};

		try {
			setLoading(true);

			const postResponse = await axios.post(
				base + additional_contract_acceptances,
				acceptanceBody,
				{
					headers: {"Content-Type": "application/json"},
				}
			);

			const newAcceptanceID = postResponse.data.id;
			if (uploadedFile && newAcceptanceID) {
				const formData = new FormData();
				formData.append("acceptance_document", uploadedFile);
				await axios.patch(
					`${base + additional_contract_acceptances}/${newAcceptanceID}/`,
					formData,
					{
						headers: {"Content-Type": "multipart/form-data"},
					}
				);
			}

			loadContent();
			toast.current?.show({
				severity: "success",
				summary: "Success",
				detail: uploadedFile
					? "Acceptance created and file uploaded successfully!"
					: "Acceptance created successfully!",
				life: 3000,
			});
			setDialogVisibility(false);
		} catch (error) {
			console.error("Error during submission:", error);
			if (error.response && error.response.data) {
				toast.current?.show({
					severity: "error",
					summary: "Error",
					detail: error.response.data.supply_contract || "Failed to add acceptance",
					life: 3000,
				});
			} else {
				toast.current?.show({
					severity: "error",
					summary: "Error",
					detail: "An unexpected error occurred.",
					life: 3000,
				});
			}
		} finally {
			setLoading(false);
		}
	};
	const totalPriceBody = rowData => {
		return <div>{(rowData.count * rowData.price).toLocaleString()}</div>;
	};

	console.log();

	return (
		<div className="col-12 p-0">
			<div className="mb-3">
				<AutoComplete
					value={selectedProject}
					suggestions={filteredProjects}
					completeMethod={searchProjects}
					field="full_name_am"
					onChange={e => {
						setSelectedProject(e.value);
						setSelectedContract(null);
						setContractProducts([]);
						setRemovedProducts([]);
					}}
					placeholder="Select Project"
					className="w-100"
					dropdown
					itemTemplate={item => (
						<div>
							<div className="text-dark">{item.full_name_am}</div>
							<small className="text-primary">
								{item.name || "No code"} (ID: {item.id})
							</small>
						</div>
					)}
				/>
			</div>

			{selectedProject && (
				<div className="mb-3">
					<AutoComplete
						value={selectedContract}
						suggestions={filteredContracts}
						completeMethod={searchContracts}
						field="formatted_contract_number"
						onChange={e => setSelectedContract(e.value)}
						placeholder="Select Contract"
						className="w-100"
						dropdown
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.formatted_contract_number}</div>
								<small className="text-primary">
									Contract Number: {item.contract_number} (ID: {item.id})
								</small>
							</div>
						)}
					/>
				</div>
			)}

			{selectedProject && selectedContract && (
				<>
					<h5>Contract Products</h5>
					<DataTable value={contractProducts} responsiveLayout="scroll" className="mb-3">
						<Column field="description" header="Description" className="col-5" />
						<Column
							header="Count"
							className="col-2 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.count || 1}
									onValueChange={e => handleCountChange(rowData.id, e.value)}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column
							header="Price"
							className="col-3 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.price || 0}
									onValueChange={e => handlePriceChange(rowData.id, e.value)}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

						<Column
							header="Remove"
							className="col-2 text-center"
							body={rowData => (
								<Button
									icon="pi pi-trash"
									className="p-button-rounded p-button-danger p-button-text"
									onClick={() => removeProduct(rowData)}
								/>
							)}
						/>
					</DataTable>

					{removedProducts.length > 0 && (
						<>
							<h5>Removed Products</h5>
							<DataTable value={removedProducts} responsiveLayout="scroll" className="mb-3">
								<Column field="description" header="Description" className="col-5" />
								<Column
									header="Count"
									className="col-2 text-center"
									body={rowData => (
										<InputNumber
											value={rowData.count || 1}
											onValueChange={e => {
												const newCount = e.value;
												setRemovedProducts(prev =>
													prev.map(p => (p.id === rowData.id ? {...p, count: newCount} : p))
												);
											}}
											mode="decimal"
											min={0}
										/>
									)}
								/>
								<Column
									header="Price"
									className="col-3 text-center"
									body={rowData => (
										<InputNumber
											value={rowData.price || 0}
											onValueChange={e => {
												const newPrice = e.value;
												setRemovedProducts(prev =>
													prev.map(p => (p.id === rowData.id ? {...p, price: newPrice} : p))
												);
											}}
											mode="decimal"
											min={0}
										/>
									)}
								/>
								<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

								<Column
									header="Re-Add"
									className="col-2 text-center"
									body={rowData => (
										<Button
											icon="pi pi-refresh"
											className="p-button-rounded p-button-success p-button-text"
											onClick={() => reAddProduct(rowData)}
										/>
									)}
								/>
							</DataTable>
						</>
					)}

					<div className="mb-3">
						<label>Acceptance Date</label>
						<Calendar
							value={uploadDate}
							onChange={e => setUploadDate(e.value)}
							dateFormat="yy-mm-dd"
							placeholder="YYYY-MM-DD"
							showIcon
							className="w-100"
						/>
					</div>

					<div className="mb-4">
						<FileUpload
							name="file"
							customUpload
							maxFileSize={5242880}
							accept="application/pdf,image/jpeg,image/png"
							auto={false}
							chooseLabel="Choose File"
							uploadHandler={e => onFileSelect(e)}
							onSelect={e => onFileSelect(e)}
							className="w-100"
						/>
						{fileError && <p style={{color: "red"}}>{fileError}</p>}
					</div>

					<div className="d-flex justify-content-center border-top pt-5">
						<Button
							className="mx-2"
							rounded
							label="Close"
							severity="secondary"
							onClick={() => setDialogVisibility(false)}
							disabled={loading}
						/>
						<Button
							className="mx-2"
							rounded
							label="Submit"
							severity="primary"
							onClick={handleSubmit}
							disabled={loading}
						/>
					</div>
				</>
			)}
		</div>
	);
}
