import React, {useState, useEffect, useRef} from "react";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import {Toast} from "primereact/toast";

import {
	base,
	partners as partnersUrl,
	additional_contracts,
	locations_url,
	projects_with_budget,
} from "../../../api";

export default function AdditionalContractEditsDialog({
	setEditDialogVisibility,
	loadContent,
	data,
}) {
	const toastRef = useRef(null);

	const [contractData, setContractData] = useState({
		deliveryTime: null,
		warrantyPeriod: null,
		paymentPeriod: null,
		startEndDate: {start: null, end: null},
		contractDate: null,
		prepaymentPercentage: null,
		contract_products: [],
		file: null,
	});

	const [oldPrepaymentPercentage, setOldPrepaymentPercentage] = useState(null);
	const [withoutVAT, setWithoutVAT] = useState(false);

	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedPartner, setSelectedPartner] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);

	const [removedProducts, setRemovedProducts] = useState([]);

	const [partners, setPartners] = useState([]);
	const [filteredPartners, setFilteredPartners] = useState([]);
	const [partnersLoading, setPartnersLoading] = useState(false);

	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [projectsLoading, setProjectsLoading] = useState(false);

	const [locations, setLocations] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [locationsLoading, setLocationsLoading] = useState(false);

	const [loading, setLoading] = useState(false);

	useEffect(() => {
		setPartnersLoading(true);
		axios
			.get(base + partnersUrl)
			.then(r => setPartners(r.data))
			.catch(error => {
				console.error("Failed to load partners:", error);
				toastRef.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to load partners.",
					life: 3000,
				});
			})
			.finally(() => setPartnersLoading(false));

		setProjectsLoading(true);
		axios
			.get(base + projects_with_budget)
			.then(r => setProjects(r.data))
			.catch(error => {
				console.error("Failed to load projects:", error);
				toastRef.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to load projects.",
					life: 3000,
				});
			})
			.finally(() => setProjectsLoading(false));

		setLocationsLoading(true);
		axios
			.get(base + locations_url)
			.then(r => setLocations(r.data))
			.catch(error => {
				console.error("Failed to load locations:", error);
				toastRef.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to load locations.",
					life: 3000,
				});
			})
			.finally(() => setLocationsLoading(false));
	}, []);

	useEffect(() => {
		if (data) {
			setContractData(prev => ({
				...prev,
				deliveryTime: data.delivery_time || null,
				warrantyPeriod: data.warranty_period || null,
				paymentPeriod: data.payment_period || null,
				startEndDate: {
					start: data.start_date ? new Date(data.start_date) : null,
					end: data.end_date ? new Date(data.end_date) : null,
				},
				contractDate: data.contract_date ? new Date(data.contract_date) : null,
				prepaymentPercentage: parseFloat(data.advance_payment) || 0,
				contract_products:
					data.contract_products?.map(prod => ({
						...prod,
						count: prod.count || 1,
						price: parseFloat(prod.price) || 0,
					})) || [],
				file: null,
			}));
			setWithoutVAT(data.without_vat || false);
		}
	}, [data]);

	useEffect(() => {
		if (!data || projects.length === 0 || partners.length === 0 || locations.length === 0) {
			return;
		}

		const projectId = data.demand_letter?.project_id || data.project;
		const projectObj = projects.find(p => p.id === projectId) || null;
		const partnerObj = partners.find(p => p.id === data.partner) || null;
		const locationObj = locations.find(l => l.id === data.location) || null;

		setSelectedProject(projectObj);
		setSelectedPartner(partnerObj);
		setSelectedLocation(locationObj);
	}, [data, projects, partners, locations]);

	useEffect(() => {
		if (data?.advance_payment) {
			setOldPrepaymentPercentage(parseFloat(data.advance_payment));
		}
	}, [data]);

	const removeProduct = p => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.filter(x => x.id !== p.id),
		}));
		setRemovedProducts(prev => [...prev, p]);
	};

	const reAddProduct = p => {
		setRemovedProducts(prev => prev.filter(x => x.id !== p.id));
		setContractData(prev => ({
			...prev,
			contract_products: [...prev.contract_products, p],
		}));
	};

	const handleCountChange = (productId, newCount) => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.map(pr =>
				pr.id === productId ? {...pr, count: newCount} : pr
			),
		}));
		setRemovedProducts(prev =>
			prev.map(pr => (pr.id === productId ? {...pr, count: newCount} : pr))
		);
	};

	const handlePriceChange = (productId, newPrice) => {
		setContractData(prev => ({
			...prev,
			contract_products: prev.contract_products.map(pr =>
				pr.id === productId ? {...pr, price: newPrice} : pr
			),
		}));
		setRemovedProducts(prev =>
			prev.map(pr => (pr.id === productId ? {...pr, price: newPrice} : pr))
		);
	};
	const handleFileUpload = event => {
		if (event.files && event.files.length > 0) {
			setContractData(prev => ({...prev, file: event.files[0]}));
			toastRef.current?.show({
				severity: "info",
				summary: "File Selected",
				detail: event.files[0].name,
				life: 3000,
			});
		}
	};
	console.log(contractData);

	const saveData = async () => {
		const validProducts = contractData.contract_products.filter(
			p => p.product && !isNaN(parseFloat(p.count))
		);

		if (validProducts.length === 0) {
			toastRef.current?.show({
				severity: "error",
				summary: "Error",
				detail: "No valid products to send.",
				life: 3000,
			});
			return;
		}

		setLoading(true);

		const requestData = {
			demand_letter_id: data?.demand_letter?.id ?? null,
			project: selectedProject?.id || "",
			partner: selectedPartner?.id || "",
			location: selectedLocation?.id || "",
			advance_payment: contractData.prepaymentPercentage || 0,
			contract_date: contractData.contractDate
				? contractData.contractDate.toISOString().split("T")[0]
				: null,
			delivery_time: parseInt(contractData.deliveryTime, 10) || 0,
			start_date: contractData.startEndDate.start
				? contractData.startEndDate.start.toISOString().split("T")[0]
				: null,
			end_date: contractData.startEndDate.end
				? contractData.startEndDate.end.toISOString().split("T")[0]
				: null,
			warranty_period: parseInt(contractData.warrantyPeriod, 10) || 0,
			payment_period: parseInt(contractData.paymentPeriod, 10) || 0,
			without_vat: withoutVAT,
			purchase_price: 0,
			contract_products: validProducts.map(p => ({
				id: p.id || null,
				product: p.product,
				count: parseInt(p.count, 10) || 1,
				description: p.description || null,
				unit: p.unit,
				price: parseFloat(p.price) || 0,
			})),
		};

		try {
			await axios.patch(`${base + additional_contracts}/${data?.id}/`, requestData, {
				headers: {"Content-Type": "application/json"},
			});
			if (contractData.file) {
				const formData = new FormData();
				formData.append("approved_document", contractData.file);
				await axios.patch(`${base + additional_contracts}/${data?.id}/`, formData, {
					headers: {"Content-Type": "multipart/form-data"},
				});
			}
			// if (contractData.file) {
			// 	const formData = new FormData();
			// 	formData.append("acceptance_document", contractData.file);

			// 	await axios.patch(`${base + additional_contracts}/${data?.id}/`, formData, {
			// 		headers: {"Content-Type": "multipart/form-data"},
			// 	});
			// }

			loadContent();
			toastRef.current?.show({
				severity: "success",
				summary: "Success",
				detail: "Contract updated successfully!",
				life: 3000,
			});
			setEditDialogVisibility(false);
		} catch (error) {
			console.error("Failed to update contract:", error?.response?.data || error.message);
			toastRef.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to update contract.",
				life: 3000,
			});
		} finally {
			setLoading(false);
		}
	};

	const handleChange = (e, field) => {
		const value = e.value !== undefined ? e.value : e.target.value;
		setContractData(prev => ({...prev, [field]: value}));
	};

	const searchProject = e => {
		const q = e.query.toLowerCase();
		setFilteredProjects(projects.filter(p => p.full_name_am?.toLowerCase().includes(q)));
	};

	// const searchProject = e => {
	// 	const q = e.query.toLowerCase();
	// 	setFilteredProjects(
	// 		projects.filter(
	// 			p =>
	// 				(p.project_full_name_am && p.project_full_name_am.toLowerCase().includes(q)) ||
	// 				(p.project_name && p.project_name.toLowerCase().includes(q))
	// 		)
	// 	);
	// };

	const searchPartner = e => {
		const q = e.query.toLowerCase();
		setFilteredPartners(
			partners.filter(
				pt =>
					(pt.full_name_am && pt.full_name_am.toLowerCase().includes(q)) ||
					(pt.company_am && pt.company_am.toLowerCase().includes(q))
			)
		);
	};

	const searchLocation = e => {
		const q = e.query.toLowerCase();
		setFilteredLocations(locations.filter(l => l.title_am?.toLowerCase().includes(q)));
	};

	const onFileSelect = e => {
		setContractData(prev => ({...prev, file: e.files[0]}));
	};

	const toggleWithoutVAT = isChecked => {
		setWithoutVAT(isChecked);
		if (isChecked) {
			if (contractData.prepaymentPercentage !== 0) {
				setOldPrepaymentPercentage(contractData.prepaymentPercentage);
			}
			setContractData(prev => ({...prev, prepaymentPercentage: 0}));
		} else {
			setContractData(prev => ({
				...prev,
				prepaymentPercentage: oldPrepaymentPercentage,
			}));
		}
	};
	const totalPriceBody = rowData => {
		return <div>{(rowData.count * rowData.price).toLocaleString()}</div>;
	};

	return (
		<>
			<Toast ref={toastRef} />

			<div className="row">
				<div className="field col-12 mb-3">
					<label htmlFor="project">Project</label>
					<AutoComplete
						id="project"
						value={selectedProject?.full_name_am}
						suggestions={filteredProjects}
						completeMethod={searchProject}
						field="project_name"
						onChange={e => {
							setSelectedProject(e.value);
							setSelectedPartner(null);
							setSelectedLocation(null);
							setContractData(prev => ({...prev, contract_products: []}));
							setRemovedProducts([]);
						}}
						placeholder="Search Project by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.full_name_am}</div>
								<small className="text-muted">{item.number}</small>
							</div>
						)}
						valueTemplate={selected =>
							selected ? `${selected.project_name} - ${selected.project_full_name_am}` : ""
						}
						loading={projectsLoading}
					/>
				</div>

				<div className="field col-6 mb-3">
					<label htmlFor="partner">Partner</label>
					<AutoComplete
						id="partner"
						value={selectedPartner}
						suggestions={filteredPartners}
						completeMethod={searchPartner}
						field="full_name_am"
						onChange={e => setSelectedPartner(e.value)}
						placeholder="Search Partner by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => <div>{item.full_name_am}</div>}
						loading={partnersLoading}
					/>
				</div>

				<div className="field col-6 mb-3">
					<label htmlFor="location">Location</label>
					<AutoComplete
						id="location"
						value={selectedLocation}
						suggestions={filteredLocations}
						completeMethod={searchLocation}
						field="title_am"
						onChange={e => setSelectedLocation(e.value)}
						placeholder="Search Location by Name"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => <div>{item.title_am}</div>}
						loading={locationsLoading}
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="contractDate">Contract Date</label>
					<Calendar
						id="contractDate"
						value={contractData.contractDate}
						onChange={e => handleChange(e, "contractDate")}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="deliveryTime">Delivery Time (days)</label>
					<InputNumber
						id="deliveryTime"
						value={contractData.deliveryTime}
						onValueChange={e => handleChange(e, "deliveryTime")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="warrantyPeriod">Warranty Period (days)</label>
					<InputNumber
						id="warrantyPeriod"
						value={contractData.warrantyPeriod}
						onValueChange={e => handleChange(e, "warrantyPeriod")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="paymentPeriod">Payment Period (days)</label>
					<InputNumber
						id="paymentPeriod"
						value={contractData.paymentPeriod}
						onValueChange={e => handleChange(e, "paymentPeriod")}
						mode="decimal"
						min={0}
						placeholder="Days"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="startEndDateStart">Start Date</label>
					<Calendar
						id="startEndDateStart"
						value={contractData.startEndDate.start}
						onChange={e =>
							setContractData(prev => ({
								...prev,
								startEndDate: {...prev.startEndDate, start: e.value},
							}))
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="startEndDateEnd">End Date</label>
					<Calendar
						id="startEndDateEnd"
						value={contractData.startEndDate.end}
						onChange={e =>
							setContractData(prev => ({
								...prev,
								startEndDate: {...prev.startEndDate, end: e.value},
							}))
						}
						dateFormat="dd.mm.yy"
						placeholder="DD.MM.YYYY"
						className="w-100"
					/>
				</div>

				<div className="field col-3 mb-3">
					<label htmlFor="prepaymentPercentage">Prepayment (%)</label>
					<InputNumber
						id="prepaymentPercentage"
						value={contractData.prepaymentPercentage}
						onValueChange={e => handleChange(e, "prepaymentPercentage")}
						mode="decimal"
						min={0}
						max={100}
						suffix="%"
						className="w-100"
						disabled={withoutVAT}
					/>
				</div>

				<div className="field col-3 d-flex align-items-center mb-3">
					<Checkbox
						inputId="withoutVAT"
						checked={withoutVAT}
						onChange={e => toggleWithoutVAT(e.checked)}
					/>
					<label htmlFor="withoutVAT" className="ml-2">
						Առանց ԱԱՀ
					</label>
				</div>

				<h5 className="mt-3">Contract Products</h5>
				<DataTable
					value={contractData.contract_products}
					responsiveLayout="scroll"
					className="mb-3"
				>
					<Column field="description" header="Description" className="col-4" />
					<Column
						header="Count"
						className="col-2 text-center"
						body={rowData => (
							<InputNumber
								value={rowData.count || 1}
								onValueChange={e => handleCountChange(rowData.id, e.value)}
								mode="decimal"
								min={0}
							/>
						)}
					/>
					<Column
						header="Price"
						className="col-3 text-center"
						body={rowData => (
							<InputNumber
								value={rowData.price || 0}
								onValueChange={e => handlePriceChange(rowData.id, e.value)}
								mode="decimal"
								min={0}
							/>
						)}
					/>
					<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

					<Column
						header="Remove"
						className="col-3 text-center"
						body={rowData => (
							<Button
								icon="pi pi-trash"
								className="p-button-rounded p-button-danger p-button-text"
								onClick={() => removeProduct(rowData)}
							/>
						)}
					/>
				</DataTable>

				{removedProducts.length > 0 && (
					<>
						<h5>Removed Products</h5>
						<DataTable value={removedProducts} responsiveLayout="scroll" className="mb-3">
							<Column field="description" header="Description" className="col-4" />
							<Column
								header="Count"
								className="col-2 text-center"
								body={rowData => (
									<InputNumber
										value={rowData.count || 1}
										onValueChange={e =>
											setRemovedProducts(prev =>
												prev.map(p => (p.id === rowData.id ? {...p, count: e.value} : p))
											)
										}
										mode="decimal"
										min={0}
									/>
								)}
							/>
							<Column
								header="Price"
								className="col-3 text-center"
								body={rowData => (
									<InputNumber
										value={rowData.price || 0}
										onValueChange={e =>
											setRemovedProducts(prev =>
												prev.map(p => (p.id === rowData.id ? {...p, price: e.value} : p))
											)
										}
										mode="decimal"
										min={0}
									/>
								)}
							/>
							<Column header="Total" className="col-3 text-center" body={totalPriceBody} />

							<Column
								header="Re-add"
								className="col-3 text-center"
								body={rowData => (
									<Button
										icon="pi pi-refresh"
										className="p-button-rounded p-button-success p-button-text"
										onClick={() => reAddProduct(rowData)}
									/>
								)}
							/>
						</DataTable>
					</>
				)}

				<div className="col-12 mb-3">
					{data?.approved_document && (
						<div className="my-3">
							<label>Current File</label>
							<div>
								{/\.(jpeg|jpg|gif|png|svg)$/i.test(data.approved_document) ? (
									<img
										src={data.approved_document}
										alt="File Preview"
										style={{maxWidth: "100%", maxHeight: "100px"}}
										className="my-3"
									/>
								) : (
									<div className="mb-2">
										<i className="pi pi-file" style={{fontSize: "2rem"}}></i>
									</div>
								)}
								<a
									href={data.approved_document}
									target="_blank"
									rel="noopener noreferrer"
									download
									className="ms-3 text-primary"
								>
									Download Current File
								</a>
							</div>
						</div>
					)}
				</div>

				<div className="col-12 mt-3 mb-5">
					<label htmlFor="fileUpload">Upload File</label>
					<FileUpload
						name="file"
						auto
						customUpload
						uploadHandler={onFileSelect}
						chooseLabel="Select File"
						className="w-100"
					/>
				</div>

				<div className="col-12 text-center">
					<Button
						label="Cancel"
						rounded
						severity="secondary"
						icon="pi pi-times"
						className="mx-2"
						onClick={() => setEditDialogVisibility(false)}
						disabled={loading}
					/>
					<Button
						rounded
						label={loading ? "Saving..." : "Save"}
						icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
						className="mx-2"
						onClick={saveData}
						disabled={loading}
					/>
				</div>
			</div>
		</>
	);
}
