import React, {useState, useEffect, useRef} from "react";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {Button} from "primereact/button";
import {Skeleton} from "primereact/skeleton";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import {FaRegFilePdf, FaFilePdf} from "react-icons/fa";

import axios from "axios";
import {base, contract_acceptances, supply_contracts} from "../../../api";
import DataTableACTApplicationDialog from "./DataTableACTApplicationDialog";
import EditACTApplicationDialog from "./EditACTApplicationDialog";

export default function DataTableACTApplication(props) {
	const headers = {"Content-Type": "application/json"};
	const credentials = "same-origin";
	const [products, setProducts] = useState([]);
	const [supplyContracts, setSupplyContracts] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [editDialogVisibility, setEditDialogVisibility] = useState(false);
	const [selectedRowData, setSelectedRowData] = useState(null);
	const toast = useRef(null);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([axios.get(base + supply_contracts), axios.get(base + contract_acceptances)])
			.then(
				axios.spread((contractsResponse, acceptancesResponse) => {
					const contracts = contractsResponse.data;
					const acceptances = acceptancesResponse.data.map(acceptance => {
						const matchingContract = contracts.find(
							contract => contract.id === acceptance.supply_contract
						);
						return {
							...acceptance,
							contract_name: matchingContract
								? matchingContract.formatted_contract_number
								: "Unknown Contract",
						};
					});

					const sortedProducts = acceptances.sort((a, b) => b.id - a.id);
					setProducts(sortedProducts);
					setSupplyContracts(contracts);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load content.",
						life: 3000,
					});
				}
			});
	};

	const fileBodyTemplate = rowData => {
		if (rowData.acceptance_document) {
			return (
				<a
					target="_blank"
					href={rowData.acceptance_document}
					rel="noopener noreferrer"
					download
					title="Download file"
				>
					<FaRegFilePdf className="fs-3 text-success" />
				</a>
			);
		} else {
			return <></>;
		}
	};

	const dateBody = rowData => {
		const formatDate = dateString => {
			const date = new Date(dateString);
			const day = String(date.getDate()).padStart(2, "0");
			const month = String(date.getMonth() + 1).padStart(2, "0");
			const year = date.getFullYear();
			return `${day}.${month}.${year}`;
		};
		return formatDate(rowData.acceptance_date);
	};

	const countBody = rowData => {
		return <div>{rowData?.acceptance_products.length}</div>;
	};

	const onRowClick = rowData => {
		if (rowData.acceptance_document) {
			if (toast.current) {
				toast.current.show({
					severity: "info",
					summary: "Info",
					detail: "This contract has a file and cannot be edited.",
					life: 3000,
				});
			}
			return;
		}
		setSelectedRowData(rowData);
		setEditDialogVisibility(true);
	};

	const confirmDelete = rowData => {
		confirmDialog({
			message: "Are you sure you want to delete this acceptance?",
			header: "Delete Confirmation",
			icon: "pi pi-info-circle",
			acceptClassName: "p-button-danger",
			accept: () => handleDelete(rowData),
			reject: () => handleReject(),
		});
	};

	const handleDelete = async rowData => {
		try {
			await axios.delete(`${base + contract_acceptances}${rowData.id}/`, {
				headers: headers,
			});
			toast.current.show({
				severity: "success",
				summary: "Success",
				detail: "Acceptance deleted successfully.",
				life: 3000,
			});
			loadContent();
		} catch (error) {
			console.error("Error deleting acceptance:", error);
			toast.current.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to delete acceptance.",
				life: 3000,
			});
		}
	};

	const handleReject = () => {
		toast.current.show({
			severity: "warn",
			summary: "Rejected",
			detail: "You have canceled the deletion.",
			life: 3000,
		});
	};

	const deleteBodyTemplate = rowData => {
		return (
			<Button
				icon="pi pi-trash"
				className="p-button-rounded p-button-danger p-button-text"
				onClick={e => {
					e.stopPropagation(); // Prevent row click
					confirmDelete(rowData);
				}}
			/>
		);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<ConfirmDialog />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label="Նոր Ակտի հավելված" rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>

			{products.length > 0 ? (
				<DataTable
					value={products}
					dataKey="id"
					tableStyle={{minWidth: "60rem"}}
					rowHover
					onRowClick={e => onRowClick(e.data)}
				>
					<Column field="contract" header="Պայմանագրի համար"></Column>
					<Column field="formatted_contract_acceptance_number" header="Ակտի համար"></Column>
					<Column header="Ամսաթիվ" body={dateBody}></Column>
					<Column header="Count" body={countBody}></Column>
					<Column field="acceptance_document" header="Ֆայլ" body={fileBodyTemplate}></Column>
					<Column body={deleteBodyTemplate} header="Delete"></Column>
				</DataTable>
			) : (
				<div>
					<span className="small">Loading, please wait...</span>
					<Skeleton className="w-100 mt-2" height="3rem"></Skeleton>
				</div>
			)}

			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Նոր Ակտի հավելված</div>}
			>
				<DataTableACTApplicationDialog
					toast={toast}
					setDialogVisibility={setDialogVisibility}
					loadContent={loadContent}
				/>
			</Dialog>

			{/* Edit Acceptance Dialog */}
			<Dialog
				visible={editDialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setEditDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Փոփոխել Ակտը</div>}
			>
				<EditACTApplicationDialog
					rowData={selectedRowData}
					toast={toast}
					closeDialog={() => setEditDialogVisibility(false)}
					refreshData={loadContent}
				/>
			</Dialog>
		</div>
	);
}
