import React from "react";
import {TabView, TabPanel} from "primereact/tabview";
import DataTableDataContracts from "./components/DataTableDataContracts";
import DataTableDataACT from "./components/DataTableDataACT";
import DataTableDataDemandLetter from "./components/DataTableDataDemandLetter";
import DataTableDataApplications from "./components/DataTableDataApplications";
import DataTableACTApplication from "./components/DataTableACTApplication";
import DatatableAdditionalAct from "./components/DatatableAdditionalAct";
import DatatableAdditionalContract from "./components/DatatableAdditionalContract";

export default function SupplyContracts() {
	return (
		<TabView>
			<TabPanel header="Պահանջագրեր" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataDemandLetter />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Մատակարարման պայմանագրեր" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableDataContracts />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Ընդունման ակտ" className="card">
				<div className="card-body">
					<p className="m-0">
						<DataTableACTApplication />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Լրացուցիչ պայմանագիր" className="card">
				<div className="card-body">
					<p className="m-0">
						<DatatableAdditionalContract />
					</p>
				</div>
			</TabPanel>
			<TabPanel header="Լրացուցիչ պայմանագրի ակտ" className="card">
				<div className="card-body">
					<p className="m-0">
						<DatatableAdditionalAct />
					</p>
				</div>
			</TabPanel>
		</TabView>
	);
}
