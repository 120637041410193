import React, {useState, useEffect, useRef} from "react";
import {Skeleton} from "primereact/skeleton";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {base, demand_letters} from "../../../api";
import axios from "axios";
import {Button} from "primereact/button";
import DemandLetterDialog from "./DemandLetterDialog";
import DemandLetterEditDialog from "./DemandLetterEditDialog";

import {FaRegFilePdf} from "react-icons/fa";
import NothingToShow from "../../ui/elements/NothingToShow";

export default function DataTableDataDemandLetter() {
	const [products, setProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [editableDialogVisibility, setEditableDialogVisibility] = useState(false); // State for editable dialog
	const [selectedRowData, setSelectedRowData] = useState(null); // State to store selected row data
	const toast = useRef(null);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		setLoading(true);
		axios
			.get(base + demand_letters)
			.then(res => {
				const sortedProducts = res.data.sort((a, b) => b.id - a.id);
				setProducts(sortedProducts);
				setLoading(false);
			})
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load data.",
						life: 3000,
					});
				}
				setLoading(false);
			});
	};

	const letterCountBody = rowData => {
		const count = rowData.demand_letter_products?.length || 0;
		return <div className="fst-italic">{count}</div>;
	};

	const projectNameBody = rowData => {
		return (
			<div className="d-grid">
				<div className="fst-italic">{rowData.project_full_name_am}</div>
				<div className="fst-italic">{rowData.project_name}</div>
			</div>
		);
	};

	const fileBody = rowData => {
		if (rowData.approved_document) {
			return (
				<a
					target="_blank"
					href={rowData.approved_document}
					rel="noopener noreferrer"
					download
					title="Download file"
				>
					<FaRegFilePdf className="fs-3 text-success" />
				</a>
			);
		}
		return null;
	};

	const onRowClick = e => {
		if (e.data.file) {
			toast.current?.show({
				severity: "info",
				summary: "Info",
				detail: "This row has a file. You can download it by clicking the download button.",
				life: 3000,
			});
			return;
		}
		setSelectedRowData(e.data); // Pass the full row data to the dialog
		setEditableDialogVisibility(true); // Open the editable dialog
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button label={"Նոր պահանջագիր"} rounded onClick={() => setDialogVisibility(true)} />
				</div>
			</div>

			{loading ? (
				<div>
					<span className="small">Loading, please wait...</span>
					<Skeleton className="w-100 mt-2" height="3rem"></Skeleton>
				</div>
			) : products.length > 0 ? (
				<DataTable
					value={products}
					dataKey="id"
					tableStyle={{minWidth: "60rem"}}
					sortField="created_at"
					sortOrder={-1}
					paginator
					rows={20}
					className="p-datatable-hoverable-rows"
					rowsPerPageOptions={[5, 10, 20, 50]}
					currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
					paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
					onRowClick={onRowClick} // Handle row click
					rowClassName={rowData => (rowData.file ? "row-disabled" : "")} // Add CSS class for rows with files
				>
					<Column field="id" header="Պահանջագրի համար" className="col-1" />
					<Column
						field="created_at"
						header="Ամսաթիվ"
						className="col-1"
						body={rowData => {
							const createdAt = rowData.date ? new Date(rowData.date) : null;
							return createdAt && !isNaN(createdAt)
								? createdAt.toLocaleDateString("en-GB", {
										day: "2-digit",
										month: "2-digit",
										year: "numeric",
								  })
								: "N/A";
						}}
					/>
					<Column header="Նախագիծ" body={projectNameBody} className="col-10" />
					<Column header="Քանակ" body={letterCountBody} className="col-1" />
					<Column header="File" body={fileBody} className="col-1" />
				</DataTable>
			) : (
				<div className="text-center py-5">
					<p>No Content Available</p>
				</div>
			)}

			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom text-uppercase py-2">Նոր պահանջագիր</div>}
			>
				<DemandLetterDialog
					setDialogVisibility={setDialogVisibility}
					loadContent={loadContent}
					toast={toast}
				/>
			</Dialog>
			<Dialog
				visible={editableDialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setEditableDialogVisibility(false)}
				header={<div className="border-bottom text-uppercase py-2">Edit Demand Letter</div>}
			>
				<DemandLetterEditDialog
					setEditableDialogVisibility={setEditableDialogVisibility}
					loadContent={loadContent}
					selectedRowData={selectedRowData}
					toast={toast}
				/>
			</Dialog>
		</div>
	);
}
