import React, {useState, useEffect, useRef} from "react";
import {Skeleton} from "primereact/skeleton";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Toast} from "primereact/toast";
import {Dialog} from "primereact/dialog";
import {base, additional_contracts, partners, projects_url} from "../../../api";
import axios from "axios";
import {Button} from "primereact/button";
import NewContractAddForm from "./NewContractAddForm";
import {Tooltip} from "primereact/tooltip";
import {FaRegFilePdf, FaFilePdf} from "react-icons/fa";
import AdditionalContractEditsDialog from "./AdditionalContractEditsDialog";
import AdditionalContractDialog from "./AdditionalContractDialog";

export default function DatatableAdditionalContract(props) {
	const [products, setProducts] = useState([]);
	const [partnersData, setPartnersData] = useState([]);
	const [projects, setProjects] = useState([]);
	const [dialogVisibility, setDialogVisibility] = useState(false);
	const [editDialogVisibility, setEditDialogVisibility] = useState(false);
	const [selectedContract, setSelectedContract] = useState(null);
	const toast = useRef(null);

	const formatDate = dateStr => {
		if (!dateStr) return "N/A";
		const date = new Date(dateStr);
		const day = String(date.getDate()).padStart(2, "0");
		const month = String(date.getMonth() + 1).padStart(2, "0");
		const year = date.getFullYear();
		return `${day}.${month}.${year}`;
	};
	// console.log(products[0]);

	useEffect(() => {
		loadContent();
	}, []);

	const loadContent = () => {
		axios
			.all([
				axios.get(base + additional_contracts),
				axios.get(base + partners),
				axios.get(base + projects_url),
			])
			.then(
				axios.spread((contractsResponse, partnersResponse, projectsResponse) => {
					const sortedProducts = contractsResponse.data.sort((a, b) => b.id - a.id);
					setProducts(sortedProducts);
					setPartnersData(partnersResponse.data);
					setProjects(projectsResponse.data);
				})
			)
			.catch(error => {
				console.error("Error loading content:", error);
				if (toast.current) {
					toast.current.show({
						severity: "error",
						summary: "Error",
						detail: "Failed to load content.",
						life: 3000,
					});
				}
			});
	};

	const percentageBody = rowData => {
		return (
			<div className="fst-italic">
				{rowData?.without_vat ? (
					"Առանց ԱԱՀ"
				) : (
					<>
						{parseInt(rowData.advance_payment)} <span className="ms-1">%</span>
					</>
				)}
			</div>
		);
	};

	const contractDateBody = rowData => {
		const formattedcontractdate = formatDate(rowData?.end_date);
		return <div className="fst-italic">{formattedcontractdate}</div>;
	};

	const contractIDBody = rowData => {
		return <div className="fst-italic">{rowData?.formatted_contract_number}</div>;
	};

	const contractStartEndDateBody = rowData => {
		const formattedStartDate = formatDate(rowData?.start_date);
		const formattedEndDate = formatDate(rowData?.end_date);

		return (
			<div className="fst-italic">
				{formattedStartDate} - {formattedEndDate}
			</div>
		);
	};

	const partnerBody = rowData => {
		const partner = partnersData.find(p => p.id === rowData.partner);
		return <div className="fst-italic">{partner ? partner.full_name_am : partner}</div>;
	};

	const projectBody = rowData => {
		const project = rowData?.demand_letter;
		return (
			<>
				<Tooltip target=".logo" mouseTrack mouseTrackLeft={10} />
				<div
					className="fst-italic logo"
					data-pr-tooltip={
						project && project.project_full_name_am
							? `${project.project_full_name_am} (${project.project})`
							: "Unknown Project"
					}
				>
					{project && project.project_full_name_am
						? `${project.project_full_name_am.substring(0, 20)} (${project.project})`
						: "Unknown Project"}
				</div>
			</>
		);
	};

	const fileBodyTemplate = rowData => {
		if (rowData.approved_document) {
			return (
				<a
					target="_blank"
					href={rowData.approved_document}
					rel="noopener noreferrer"
					download
					title="Download file"
				>
					<FaRegFilePdf className="fs-3 text-success" />
				</a>
			);
		} else {
			return <></>; // Render empty if no file
		}
	};

	const onRowClick = e => {
		if (e.data.approved_document) {
			if (toast.current) {
				toast.current.show({
					severity: "info",
					summary: "Info",
					detail: "This contract has a file and cannot be edited.",
					life: 3000,
				});
			}
			return;
		}
		setSelectedContract(e.data);
		setEditDialogVisibility(true);
	};

	return (
		<div className="card">
			<Toast ref={toast} />
			<div className="row pb-5">
				<div className="col text-end">
					<Button
						label={"Նոր Լրացուցիչ պայմանագիր"}
						rounded
						onClick={() => setDialogVisibility(true)}
					/>
				</div>
			</div>
			{products.length > 0 ? (
				<div>
					<DataTable
						value={products}
						dataKey="id"
						tableStyle={{minWidth: "60rem"}}
						paginator
						rows={10}
						rowsPerPageOptions={[5, 10, 20, 50]}
						currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
						paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
						className="p-datatable-hoverable-rows"
						rowClassName={rowData => (rowData.approved_document ? "non-editable-row" : "")}
						onRowClick={onRowClick}
					>
						<Column header="Պայմանագրի համար" body={contractIDBody} className="col-1" />
						<Column header="Մատակարար" body={partnerBody} className="col-3" />
						<Column field="delivery_time" header="Մատակարարման ժամկետ" className="col-1" />
						<Column field="warranty_period" header="Երաշխիքային ժամկետ" className="col-1" />
						<Column header="Ծրագիր" body={projectBody} />
						<Column header="Սկիզբ / Ավարտ" body={contractStartEndDateBody} className="col-3" />
						<Column header="Պայմանագրի ամսաթիվ" body={contractDateBody} />
						<Column header="Կանխավճարի տոկոս" body={percentageBody} />
						<Column field="approved_document" header="Ֆայլ" body={fileBodyTemplate}></Column>
					</DataTable>
				</div>
			) : (
				<div>
					<span className="small">Loading Please wait</span>
					<Skeleton className="w-100 mt-2" height="3rem"></Skeleton>
				</div>
			)}
			<Dialog
				visible={dialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setDialogVisibility(false)}
				header={<div className="border-bottom pt-2">Լրացուցիչ պայմանագիր</div>}
			>
				<AdditionalContractDialog
					setDialogVisibility={setDialogVisibility}
					toast={toast}
					loadContent={loadContent}
				/>
			</Dialog>
			<Dialog
				visible={editDialogVisibility}
				modal
				className="col-8 mx-auto"
				onHide={() => setEditDialogVisibility(false)}
				header={
					<div className="border-bottom pt-2">
						Խմբագրել լրացուցիչ պայմանագիր {selectedContract?.formatted_contract_number}
					</div>
				}
			>
				<AdditionalContractEditsDialog
					setEditDialogVisibility={setEditDialogVisibility}
					toast={toast}
					loadContent={loadContent}
					data={selectedContract}
				/>
			</Dialog>
		</div>
	);
}
