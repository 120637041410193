import React, {useState, useEffect} from "react";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {Calendar} from "primereact/calendar";
import {InputNumber} from "primereact/inputnumber";
import {Checkbox} from "primereact/checkbox";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {
	base,
	partners as partnersUrl,
	demand_letters,
	expances_row_by_project,
	supply_contracts,
	locations_url,
	projects_with_budget,
} from "../../../api";

export default function NewContractAddForm({setDialogVisibility, loadContent, toast}) {
	const [contractData, setContractData] = useState({
		deliveryTime: "",
		warrantyPeriod: "",
		paymentPeriod: "",
		startEndDate: {start: null, end: null},
		contractDate: null,
		prepaymentPercentage: null,
	});

	const [withoutVAT, setWithoutVAT] = useState(false);
	const [selectedProject, setSelectedProject] = useState(null);
	const [selectedBudget, setSelectedBudget] = useState(null);
	const [selectedPartner, setSelectedPartner] = useState(null);
	const [selectedLocation, setSelectedLocation] = useState(null);

	const [demandLetters, setDemandLetters] = useState([]);
	const [filteredDemandLettersForProject, setFilteredDemandLettersForProject] = useState([]);
	const [selectedDemandLetter, setSelectedDemandLetter] = useState(null);

	// Local state for demand letter products
	const [demandLetterProducts, setDemandLetterProducts] = useState([]);
	const [removedProducts, setRemovedProducts] = useState([]);

	const [partners, setPartners] = useState([]);
	const [filteredPartners, setFilteredPartners] = useState([]);
	const [projects, setProjects] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [budgetOptions, setBudgetOptions] = useState([]);
	const [filteredBudgetOptions, setFilteredBudgetOptions] = useState([]);
	const [locations, setLocations] = useState([]);
	const [filteredLocations, setFilteredLocations] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		axios
			.get(base + partnersUrl)
			.then(r => setPartners(r.data))
			.catch(() => {});
		axios
			.get(base + projects_with_budget)
			.then(r => setProjects(r.data))
			.catch(() => {});
		axios
			.get(base + locations_url)
			.then(r => setLocations(r.data))
			.catch(() => {});
		axios
			.get(base + demand_letters)
			.then(r => setDemandLetters(r.data))
			.catch(() => {});
	}, []);
	console.log(demandLetters);

	useEffect(() => {
		if (selectedProject?.id) {
			axios
				.get(base + expances_row_by_project + selectedProject.id + "/")
				.then(r => setBudgetOptions(r.data))
				.catch(() => setBudgetOptions([]));
		} else {
			setBudgetOptions([]);
			setSelectedBudget(null);
		}
	}, [selectedProject]);

	useEffect(() => {
		if (selectedProject && selectedBudget) {
			const filtered = demandLetters.filter(dl => dl.project === selectedProject.name);
			setFilteredDemandLettersForProject(filtered);
			setSelectedDemandLetter(null);
		} else {
			setFilteredDemandLettersForProject([]);
			setSelectedDemandLetter(null);
		}
	}, [selectedProject, selectedBudget, demandLetters]);

	useEffect(() => {
		if (selectedDemandLetter) {
			const initialProducts =
				selectedDemandLetter.demand_letter_products?.map(p => ({
					...p,
					price: p.price || 0,
					count: p.count || 1, // If no count is given, default to 1
				})) || [];
			setDemandLetterProducts(initialProducts);
			setRemovedProducts([]);
		} else {
			setDemandLetterProducts([]);
			setRemovedProducts([]);
		}
	}, [selectedDemandLetter]);

	// Generic state changes for contract data
	const handleChange = (e, field) => {
		const value = e.value !== undefined ? e.value : e.target.value;
		setContractData(prev => ({...prev, [field]: value}));
	};

	// Remove/re-add product
	const removeProduct = rowData => {
		setDemandLetterProducts(prev => prev.filter(p => p.id !== rowData.id));
		setRemovedProducts(prev => [...prev, rowData]);
	};

	const reAddProduct = rowData => {
		setRemovedProducts(prev => prev.filter(p => p.id !== rowData.id));
		setDemandLetterProducts(prev => [...prev, rowData]);
	};

	// Change for product count
	const handleCountChange = (productId, newCount) => {
		setDemandLetterProducts(prev =>
			prev.map(p => (p.id === productId ? {...p, count: newCount} : p))
		);
	};

	// Change for product price
	const handlePriceChange = (productId, newPrice) => {
		setDemandLetterProducts(prev =>
			prev.map(p => (p.id === productId ? {...p, price: newPrice} : p))
		);
	};

	const saveData = () => {
		if (!selectedProject || !selectedBudget || !selectedPartner || !selectedLocation) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Please select a project, budget, partner, and location.",
				life: 3000,
			});
			return;
		}

		setLoading(true);

		const requestData = {
			partner: selectedPartner?.id,
			budget: selectedBudget?.id,
			location: selectedLocation?.id,
			advance_payment: contractData.prepaymentPercentage || 0,
			contract_date: contractData.contractDate
				? contractData.contractDate.toISOString().split("T")[0]
				: null,
			delivery_time: parseInt(contractData.deliveryTime, 10) || 0,
			start_date: contractData.startEndDate.start
				? contractData.startEndDate.start.toISOString().split("T")[0]
				: null,
			end_date: contractData.startEndDate.end
				? contractData.startEndDate.end.toISOString().split("T")[0]
				: null,
			warranty_period: parseInt(contractData.warrantyPeriod, 10) || 0,
			payment_period: parseInt(contractData.paymentPeriod, 10) || 0,
			without_vat: withoutVAT,
			purchase_price: 0,
			demand_letter_id: selectedDemandLetter?.id || null,
			contract_products: demandLetterProducts.map(prod => ({
				product: prod.product,
				count: prod.count,
				description: prod.description,
				unit: prod.unit,
				price: prod.price || 0,
			})),
		};

		axios
			.post(base + supply_contracts, requestData)
			.then(() => {
				setDialogVisibility(false);
				loadContent();
				toast.current?.show({
					severity: "success",
					summary: "Success",
					detail: "Contract saved successfully!",
					life: 3000,
				});
			})
			.catch(() => {
				toast.current?.show({
					severity: "error",
					summary: "Error",
					detail: "Failed to save contract.",
					life: 3000,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const searchProject = event => {
		const q = event.query.toLowerCase();
		setFilteredProjects(
			projects.filter(
				proj =>
					proj.full_name_am.toLowerCase().includes(q) ||
					proj.name.toLowerCase().includes(q) ||
					String(proj.id).toLowerCase().includes(q)
			)
		);
	};

	const searchPartner = event => {
		const q = event.query.toLowerCase();
		setFilteredPartners(
			partners.filter(
				p =>
					(p.full_name_am?.toLowerCase() || "").includes(q) ||
					(p.company_am?.toLowerCase() || "").includes(q) ||
					(p.passport?.toLowerCase() || "").includes(q) ||
					(p.phone?.toLowerCase() || "").includes(q) ||
					String(p.id).toLowerCase().includes(q)
			)
		);
	};

	const searchBudget = event => {
		const q = event.query.toLowerCase();
		setFilteredBudgetOptions(
			budgetOptions.filter(
				b =>
					b.category.title_am.toLowerCase().includes(q) ||
					b.category.code?.toLowerCase().includes(q)
			)
		);
	};

	const searchLocation = event => {
		const q = event.query.toLowerCase();
		setFilteredLocations(locations.filter(loc => loc.title_am.toLowerCase().includes(q)));
	};

	const totalPriceBody = rowData => {
		return <div>{(rowData.count * rowData.price).toLocaleString()}</div>;
	};
	return (
		<>
			<div className="field col-12 mb-3">
				<label htmlFor="project">Select Project</label>
				<AutoComplete
					id="project"
					value={selectedProject}
					suggestions={filteredProjects}
					completeMethod={searchProject}
					field="full_name_am"
					onChange={e => {
						setSelectedProject(e.value);
						setSelectedBudget(null);
						setSelectedDemandLetter(null);
					}}
					placeholder="Search Project by Name or ID"
					dropdown
					className="w-100"
					panelStyle={{width: "300px"}}
					itemTemplate={item => (
						<div>
							<div className="text-dark">{item.full_name_am}</div>
							<small className="text-primary">
								{item.name || "No code available"} (ID: {item.id})
							</small>
						</div>
					)}
				/>
			</div>

			{selectedProject && (
				<div className="field col-12 mb-3">
					<label htmlFor="budget">Select Budget</label>
					<AutoComplete
						id="budget"
						value={selectedBudget}
						suggestions={filteredBudgetOptions}
						completeMethod={searchBudget}
						field="category.title_am"
						onChange={e => {
							setSelectedBudget(e.value);
							setSelectedDemandLetter(null);
						}}
						placeholder="Search Budget by Name or Code"
						dropdown
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.category.title_am}</div>
								<small className="text-primary">{item.category.code || "No code available"}</small>
							</div>
						)}
					/>
				</div>
			)}

			{selectedProject && selectedBudget && (
				<div className="field col-12 mb-3">
					<label htmlFor="demandLetter">Select Demand Letter</label>
					<Dropdown
						id="demandLetter"
						value={selectedDemandLetter}
						options={filteredDemandLettersForProject}
						optionLabel="project_full_name_am"
						onChange={e => setSelectedDemandLetter(e.value)}
						placeholder="Select Demand Letter"
						className="w-100"
						panelStyle={{width: "300px"}}
						itemTemplate={item => (
							<div>
								<div className="text-dark">
									Project: {item.project_full_name_am || item.project}
								</div>
								<small className="text-primary">Date: {item.date}</small>
							</div>
						)}
					/>
				</div>
			)}

			{selectedProject && selectedBudget && selectedDemandLetter && (
				<>
					<div className="p-fluid row p-2">
						<div className="field col-3 mb-3">
							<label htmlFor="partner">Select Partner</label>
							<AutoComplete
								id="partner"
								value={selectedPartner}
								suggestions={filteredPartners}
								completeMethod={searchPartner}
								field="full_name_am"
								onChange={e => setSelectedPartner(e.value)}
								placeholder="Search Partner by Name"
								dropdown
								className="w-100"
								panelStyle={{width: "200px"}}
								itemTemplate={item => (
									<div className="d-grid">
										<div className="text-dark">{item.full_name_am || item.company_am}</div>
										<small className="text-primary">Passport: {item.passport || "-"}</small>
										<small className="text-primary">Phone: {item.phone || "-"}</small>
									</div>
								)}
							/>
						</div>

						<div className="field col-3 mb-3">
							<label htmlFor="location">Select Location</label>
							<AutoComplete
								id="location"
								value={selectedLocation}
								suggestions={filteredLocations}
								completeMethod={searchLocation}
								field="title_am"
								onChange={e => setSelectedLocation(e.value)}
								placeholder="Search Location by Name or ID"
								dropdown
								className="w-100"
								panelStyle={{width: "200px"}}
								itemTemplate={item => (
									<div className="d-grid">
										<div className="text-dark">{item.title_am}</div>
									</div>
								)}
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="contractDate">Contract Date</label>
							<Calendar
								id="contractDate"
								value={contractData.contractDate}
								onChange={e => handleChange(e, "contractDate")}
								dateFormat="dd.mm.yy"
								placeholder="DD.MM.YYYY"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="deliveryTime">Delivery Time (days)</label>
							<InputNumber
								id="deliveryTime"
								value={contractData.deliveryTime}
								onValueChange={e => handleChange(e, "deliveryTime")}
								mode="decimal"
								min={0}
								placeholder="Days"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="warrantyPeriod">Warranty Period</label>
							<InputNumber
								id="warrantyPeriod"
								value={contractData.warrantyPeriod}
								onValueChange={e => handleChange(e, "warrantyPeriod")}
								mode="decimal"
								min={0}
								placeholder="Days"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="paymentPeriod">Payment Period</label>
							<InputNumber
								id="paymentPeriod"
								value={contractData.paymentPeriod}
								onValueChange={e => handleChange(e, "paymentPeriod")}
								mode="decimal"
								min={0}
								placeholder="Days"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="startEndDateStart">Start Date</label>
							<Calendar
								id="startEndDateStart"
								value={contractData.startEndDate.start}
								onChange={e =>
									setContractData(prev => ({
										...prev,
										startEndDate: {
											...prev.startEndDate,
											start: e.value,
										},
									}))
								}
								dateFormat="dd.mm.yy"
								placeholder="DD.MM.YYYY"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="startEndDateEnd">End Date</label>
							<Calendar
								id="startEndDateEnd"
								value={contractData.startEndDate.end}
								onChange={e =>
									setContractData(prev => ({
										...prev,
										startEndDate: {
											...prev.startEndDate,
											end: e.value,
										},
									}))
								}
								dateFormat="dd.mm.yy"
								placeholder="DD.MM.YYYY"
							/>
						</div>

						<div className="field col-3">
							<label htmlFor="prepaymentPercentage">Prepayment (%)</label>
							<InputNumber
								id="prepaymentPercentage"
								value={contractData.prepaymentPercentage}
								onValueChange={e => handleChange(e, "prepaymentPercentage")}
								mode="decimal"
								min={0}
								max={100}
								suffix="%"
							/>
						</div>

						<div className="field col-3 d-flex align-items-center mt-4 pt-4">
							<Checkbox
								inputId="withoutVAT"
								checked={withoutVAT}
								onChange={e => setWithoutVAT(e.checked)}
							/>
							<label htmlFor="withoutVAT" className="ml-2">
								Առանց ԱԱՀ
							</label>
						</div>
					</div>

					<h5>Selected Demand Letter Products</h5>
					<DataTable value={demandLetterProducts} responsiveLayout="scroll" className="mb-3">
						<Column field="description" header="Description" className="col-4" />
						<Column
							header="Count"
							className="col-2 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.count}
									onValueChange={e => handleCountChange(rowData.id, e.value)}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column
							header="Price"
							className="col-3 text-center"
							body={rowData => (
								<InputNumber
									value={rowData.price}
									onValueChange={e => handlePriceChange(rowData.id, e.value)}
									mode="decimal"
									min={0}
								/>
							)}
						/>
						<Column header="Total" className="col-3 text-center" body={totalPriceBody} />
						<Column
							header="Remove"
							className="col-3 text-center"
							body={rowData => (
								<Button
									icon="pi pi-trash"
									className="p-button-rounded p-button-danger p-button-text"
									onClick={() => removeProduct(rowData)}
								/>
							)}
						/>
					</DataTable>

					{removedProducts.length > 0 && (
						<>
							<h5>Removed Products</h5>
							<DataTable value={removedProducts} responsiveLayout="scroll">
								<Column field="description" header="Description" className="col-4" />
								<Column
									header="Count"
									className="col-2 text-center"
									body={rowData => (
										<InputNumber
											value={rowData.count}
											onValueChange={e => handleCountChange(rowData.id, e.value)}
											mode="decimal"
											min={0}
										/>
									)}
								/>
								<Column
									header="Price"
									className="col-3 text-center"
									body={rowData => (
										<InputNumber
											value={rowData.price}
											onValueChange={e => handlePriceChange(rowData.id, e.value)}
											mode="decimal"
											min={0}
										/>
									)}
								/>
								<Column
									header="Re-Add"
									className="col-3 text-center"
									body={rowData => (
										<Button
											icon="pi pi-refresh"
											className="p-button-rounded p-button-success p-button-text"
											onClick={() => reAddProduct(rowData)}
										/>
									)}
								/>
							</DataTable>
						</>
					)}

					<div className="col-12 text-center my-4">
						<Button
							label="Cancel"
							icon="pi pi-times"
							rounded
							className="p-button-secondary mx-2"
							onClick={() => setDialogVisibility(false)}
							disabled={loading}
						/>
						<Button
							label={loading ? "Saving..." : "Save"}
							icon={loading ? "pi pi-spin pi-spinner" : "pi pi-check"}
							rounded
							className="mx-2"
							onClick={saveData}
							disabled={loading}
						/>
					</div>
				</>
			)}
		</>
	);
}
