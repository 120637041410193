import React, {useState, useEffect} from "react";
import axios from "axios";
import {AutoComplete} from "primereact/autocomplete";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {Button} from "primereact/button";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {FileUpload} from "primereact/fileupload";
import {
	base,
	unit,
	expance_type,
	expances_row_by_project,
	demand_letters,
	projects_with_budget,
} from "../../../api";

export default function DemandLetterEditDialog({
	selectedRowData,
	setEditableDialogVisibility,
	toast,
	loadContent,
}) {
	const [projectOptions, setProjectOptions] = useState([]);
	const [budgetOptions, setBudgetOptions] = useState([]);
	const [unitOptions, setUnitOptions] = useState([]);
	const [expenseTypeOptions, setExpenseTypeOptions] = useState([]);
	const [filteredProjects, setFilteredProjects] = useState([]);
	const [filteredBudgets, setFilteredBudgets] = useState([]);
	const [filteredUnits, setFilteredUnits] = useState([]);
	const [filteredExpenseTypes, setFilteredExpenseTypes] = useState([]);
	const [project, setProject] = useState(selectedRowData?.project || null);
	const [budget, setBudget] = useState(null);
	const [products, setProducts] = useState([]);
	const [uploadedFile, setUploadedFile] = useState(null);
	const [selectedDate, setSelectedDate] = useState(
		selectedRowData?.date ? new Date(selectedRowData.date) : null
	);

	useEffect(() => {
		axios
			.get(base + projects_with_budget)
			.then(res => setProjectOptions(res.data))
			.catch(error => console.error("Error fetching project options:", error));

		axios
			.get(base + unit)
			.then(res => setUnitOptions(res.data))
			.catch(error => console.error("Error fetching units:", error));

		axios
			.get(base + expance_type)
			.then(res => setExpenseTypeOptions(res.data))
			.catch(error => console.error("Error fetching expense types:", error));

		axios
			.get(base + expances_row_by_project + selectedRowData.project_id + "/")
			.then(res => {
				setBudgetOptions(res.data);
				const selectedBudget = res.data.find(
					budget => budget.id === selectedRowData.budget_category
				);
				setBudget(selectedBudget);
			})
			.catch(error => console.error("Error fetching budgets:", error));
	}, [selectedRowData]);

	useEffect(() => {
		if (selectedRowData?.demand_letter_products) {
			setProducts(
				selectedRowData.demand_letter_products.map(product => ({
					...product,
					product: expenseTypeOptions.find(e => e.id === product.product) || null,
					unit: unitOptions.find(u => u.id === product.unit) || null,
				}))
			);
		}
	}, [selectedRowData, expenseTypeOptions, unitOptions]);

	const updateProduct = (value, field, rowIndex) => {
		const updatedProducts = [...products];
		updatedProducts[rowIndex] = {
			...updatedProducts[rowIndex],
			[field]: value,
		};
		setProducts(updatedProducts);
	};

	const searchProjects = event => {
		const query = event.query.toLowerCase();
		setFilteredProjects(
			projectOptions.filter(
				p =>
					p.full_name_am.toLowerCase().includes(query) ||
					p.name.toLowerCase().includes(query) ||
					String(p.id).toLowerCase().includes(query)
			)
		);
	};

	const searchBudgets = event => {
		const query = event.query.toLowerCase();
		setFilteredBudgets(
			budgetOptions.filter(
				b =>
					b.category.title_am.toLowerCase().includes(query) ||
					b.category.code?.toLowerCase().includes(query) ||
					String(b.id).toLowerCase().includes(query)
			)
		);
	};

	const searchUnits = event => {
		const query = event.query.toLowerCase();
		const filtered = unitOptions.filter(u => u.title_am.toLowerCase().includes(query));
		setFilteredUnits(filtered);
	};

	const searchExpenseTypes = event => {
		const query = event.query.toLowerCase();
		setFilteredExpenseTypes(
			expenseTypeOptions.filter(e => e.title_am.toLowerCase().includes(query))
		);
	};

	const handleFileUpload = event => {
		setUploadedFile(event.files[0]);
		toast.current?.show({
			severity: "info",
			summary: "File Uploaded",
			detail: event.files[0].name,
			life: 3000,
		});
	};

	const addRow = () => {
		setProducts([
			...products,
			{
				product: null,
				unit: null,
				count: "",
				description: "",
			},
		]);
	};

	const removeRow = rowIndex => {
		setProducts(products.filter((_, index) => index !== rowIndex));
	};
	console.log(selectedRowData);

	const saveData = async () => {
		if (!project || !budget) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Project and Budget are required.",
				life: 3000,
			});
			return;
		}

		const validProducts = products.filter(row => row.product?.id && !isNaN(Number(row.count)));

		if (validProducts.length === 0) {
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "No valid products to send.",
				life: 3000,
			});
			return;
		}

		try {
			const requestData = {
				project_id: project.id,
				budget_category: budget.id,
				date: selectedDate ? selectedDate.toISOString().split("T")[0] : null,
				demand_letter_products: validProducts.map(row => ({
					id: row.id || null,
					product: row.product.id,
					count: parseInt(row.count, 10),
					unit: row.unit?.id || null,
					description: row.description || null,
				})),
			};

			await axios.patch(`${base}${demand_letters}${selectedRowData.id}/`, requestData, {
				headers: {"Content-Type": "application/json"},
			});

			if (uploadedFile) {
				const formData = new FormData();
				formData.append("approved_document", uploadedFile);

				await axios.patch(`${base}${demand_letters}${selectedRowData.id}/`, formData, {
					headers: {"Content-Type": "multipart/form-data"},
				});
			}

			toast.current?.show({
				severity: "success",
				summary: "Success",
				detail: "Demand letter updated successfully!",
				life: 3000,
			});

			setEditableDialogVisibility(false);
			loadContent();
		} catch (error) {
			console.error("Error saving data:", error.response?.data || error.message);
			toast.current?.show({
				severity: "error",
				summary: "Error",
				detail: "Failed to update demand letter.",
				life: 3000,
			});
		}
	};

	return (
		<div>
			<div className="row">
				<div className="col-6 mb-3">
					<label className="fw-bolder mb-2">Project</label>
					<AutoComplete
						value={project}
						suggestions={filteredProjects}
						completeMethod={searchProjects}
						field="full_name_am"
						onChange={e => setProject(e.value)}
						placeholder="Search by name or ID"
						dropdown
						panelStyle={{width: "300px"}}
						className="w-100"
						itemTemplate={item => (
							<div>
								<div className="text-dark">{item.full_name_am}</div>
								<small className="text-primary">
									{item.name || "No code available"} (ID: {item.id})
								</small>
							</div>
						)}
					/>
				</div>
				<div className="col-6">
					<label className="fw-bolder mb-3">Select Budget</label>
					<AutoComplete
						value={budget}
						suggestions={filteredBudgets}
						completeMethod={searchBudgets}
						field="category.title_am"
						onChange={e => setBudget(e.value)}
						placeholder="Search or Select Budget"
						dropdown
						panelStyle={{width: "300px"}}
						className="w-100"
					/>
				</div>
			</div>
			<div className="mt-4">
				<h5>Select Date</h5>
				<Calendar
					value={selectedDate}
					onChange={e => setSelectedDate(e.value)}
					dateFormat="dd.mm.yy"
					className="mb-3 col-3"
					showIcon
				/>
			</div>
			<div className="mt-4">
				<h5>Demand Letter Products</h5>
				<DataTable value={products} editMode="cell">
					<Column
						field="product_name"
						header="Product"
						body={(rowData, {rowIndex}) => (
							<AutoComplete
								value={rowData.product}
								suggestions={filteredExpenseTypes}
								completeMethod={e => {
									const query = e.query.toLowerCase();
									setFilteredExpenseTypes(
										expenseTypeOptions.filter(item => item.title_am.toLowerCase().includes(query))
									);
								}}
								field="title_am"
								onChange={e => updateProduct(e.value, "product", rowIndex)}
								dropdown
								panelStyle={{width: "300px"}}
								className="w-100"
								placeholder="Select or Search Product"
							/>
						)}
					/>
					<Column
						field="unit.title_am"
						header="Unit"
						body={(rowData, {rowIndex}) => (
							<AutoComplete
								value={rowData.unit}
								suggestions={filteredUnits}
								completeMethod={e => {
									const query = e.query.toLowerCase();
									setFilteredUnits(
										unitOptions.filter(item => item.title_am.toLowerCase().includes(query))
									);
								}}
								field="title_am"
								onChange={e => updateProduct(e.value, "unit", rowIndex)}
								dropdown
								panelStyle={{width: "300px"}}
								className="w-100"
								placeholder="Select or Search Unit"
							/>
						)}
					/>

					<Column
						field="count"
						header="Count"
						body={(rowData, {rowIndex}) => (
							<InputText
								value={rowData.count}
								onChange={e => updateProduct(e.target.value, "count", rowIndex)}
								className="w-100"
							/>
						)}
					/>
					<Column
						field="description"
						header="Description"
						body={(rowData, {rowIndex}) => (
							<InputText
								value={rowData.description || ""}
								onChange={e => updateProduct(e.target.value, "description", rowIndex)}
								className="w-100"
							/>
						)}
					/>
					<Column
						body={(rowData, {rowIndex}) => (
							<Button
								icon="pi pi-trash"
								className="p-button-danger"
								onClick={() => removeRow(rowIndex)}
							/>
						)}
					/>
				</DataTable>
				<Button
					label="Add Row"
					icon="pi pi-plus"
					className="p-button-success mt-3"
					onClick={addRow}
				/>
			</div>
			<div className="mt-4">
				<h5>Upload Document</h5>
				<FileUpload
					name="file"
					customUpload
					auto
					uploadHandler={handleFileUpload}
					chooseLabel="Select Document"
					className="w-100"
				/>
			</div>
			<div className="mt-4 text-end">
				<Button
					label="Cancel"
					icon="pi pi-times"
					className="p-button-secondary mr-2"
					onClick={() => setEditableDialogVisibility(false)}
				/>
				<Button label="Save" icon="pi pi-check" className="p-button-primary" onClick={saveData} />
			</div>
		</div>
	);
}
